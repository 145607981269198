<template>
  <div class="product-detail">
    <div class="detail-left">
      <!-- <el-collapse class="my-collapse" v-model="myDetail" accordion @change="handleChangeCollapse">
        <el-collapse-item v-for="(item,index) in productArr" :key="item.id" :title="$t('bilingual0030')" :name="index">
          <template slot="title">
            {{ item.name }} <i :class="judgeActive(index) ? 'downArrow2':'downArrow1'"></i>
          </template>
          <div class="spare-parts">
            <div class="spare-parts-name" v-for="(it, i) in item.children" :key="i">
              <span class="spare-parts-name-text" :class="{ 'select-part': transitionChildIndex == i }" @click="handleSelectProduct(it,i,index)">{{ it.title }}</span>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse> -->
      <!-- {{ productArr }} -->
       <el-table
        :data="productArr"
        style="width: 100%;border-top: 1px solid #EBEEF5;"
        row-key="id"
        :expand-row-keys="expandedRowKeys"
        :header-row-style="{ 'display': 'none' }"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span v-if="!row.bottomLevel" class="name4" :class="{ 'select-part': transitionChildIndex == row.id }" @click="handleSelectProduct(row)">{{ row.name }}</span>
            <span v-else-if="row.level == 1" class="name1">{{ row.name }}</span>
            <span v-else-if="row.level == 2 || row.level == 3" class="name2">{{ row.name }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="detail-right">
      <transition name="el-zoom-in-center">
        <div v-show="detailType == 'image'" class="transition-box">
          <div class="big-image">
            <img class="big-image-content" :src="imageDetail.photoDetail" alt="">
            <div class="image-name">{{ imageDetail.title }}</div>
          </div>
          <div class="image-bottom">
            <div class="image-bottom-descript">
              {{ imageDetail.desc }}
            </div>
            <div class="image-bottom-btn">
              <div class="cl-btb" @click="handleSwitchType('table')">{{ $t("bilingual0034") }}</div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="el-zoom-in-center">
        <div v-show="detailType == 'table'" class="transition-box table-box">
          <div class="table-box-content">
            <img :src="imageDetail.photo" alt="">
          </div>
          <div class="table-box-btn">
            <img src="@/assets/Icons/return-left.png" alt="" style="width:100px;height:100px;cursor: pointer;" @click="handleSwitchType('image')">
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: 'productDetail1',
  data() {
    return {
      myDetail: 0,
      transitionIndex: 0,
      transitionChildIndex: 0,
      detailType: 'image',
      productArr1: [{ id: '1', name: '漂浮管' },{ id: '2', name: '漂浮管' },{ id: '3', name: '漂浮管' },{ id: '4', name: '漂浮管' },{ id: '5', name: '漂浮管' },{ id: '6', name: '漂浮管' },{ id: '7', name: '漂浮管' }],
      productArr2: [{ id: '1', name: '漂浮管' },{ id: '2', name: '漂浮管' },{ id: '3', name: '漂浮管' },{ id: '4', name: '漂浮管' },{ id: '5', name: '漂浮管' },{ id: '6', name: '漂浮管' },{ id: '7', name: '漂浮管' }],
      productArr3: [{ id: '1', name: '漂浮管' },{ id: '2', name: '漂浮管' },{ id: '3', name: '漂浮管' },{ id: '4', name: '漂浮管' },{ id: '5', name: '漂浮管' },{ id: '6', name: '漂浮管' },{ id: '7', name: '漂浮管' }],
      productArr4: [{ id: '1', name: '漂浮管' },{ id: '2', name: '漂浮管' },{ id: '3', name: '漂浮管' },{ id: '4', name: '漂浮管' },{ id: '5', name: '漂浮管' },{ id: '6', name: '漂浮管' },{ id: '7', name: '漂浮管' }],
      tableData: [],
      productArr: [],
      imageDetail: {},
      expandedRowKeys: [], // 新增此属性
    }
  },
  props: {
    detailId: {
      type: Number,
      default: 0,
      validator(value) {
        console.log('Received new detailId in child component:', value);
        return true;
      }
    },
    productList: {
      type: Array,
      default: [],
    }
  },
  computed: {
    productChildArr() {
      console.log('productChildArr', this.productArr[this.transitionIndex]?.children)
      return this.productArr[this.transitionIndex]?.children;
    }
  },
  watch: {
    detailId(val) {
      console.log(174,val)
       // 根据分类的数量来设置 expandedRowKeys
  console.log('0999',this.productArr[0].id)
    this.expandedRowKeys = [1];
      this.myDetail = val;
      this.imageDetail = this.productList[val]?.children?.length > 0 ? (
        this.productList[val]?.children[0]?.children?.length > 0 ? ( 
          this.productList[val]?.children[0]?.children[0]?.children?.length > 0 ? (
            this.productList[val]?.children[0]?.children[0]?.children[0]?.children?.length > 0 ? this.productList[val]?.children[0]?.children[0]?.children[0].children[0] : this.productList[val]?.children[0]?.children[0]?.children[0]
          ) : this.productList[val]?.children[0]?.children[0]?.children[0] 
        ) : this.productList[val]?.children[0]
      ) : this.productList[val];
      console.log(108, this.imageDetail)
      this.transitionChildIndex = this.imageDetail.id
    },
    myDetail(val) {
      this.transitionIndex = 0;
      let _this = this;
      setTimeout(() => {
        console.log(88)
        _this.transitionIndex = val;
      },200)
    },
    productList(newValue, oldValue) {
      this.productArr = newValue;
      console.log(212, this.productArr)
      
    }
  },
  created(){
    this.productArr = this.productList
     
  },
  mounted() {
 
  
  // ...
},
  methods: {
    judgeActive(data){
      console.log(190, this.myDetail)
      return this.myDetail === '' ? false : this.myDetail == data;
    },
    handleSwitchType(type) {
      this.detailType = '';
      let _this = this;
      setTimeout(() => {
        console.log(88)
        _this.detailType = type;
      },200)
    },
    handleChangeCollapse(val) {
      console.log(201, val)
      if(val == '') {
        this.myDetail = '';
        this.$emit('handoeCloseChildCollapse')
      }
      this.detailType = '';
      let _this = this;
      setTimeout(() => {
        console.log(141)
        _this.detailType = 'image';
      },200)
    },
    handleSelectProduct(row) {
      console.log(145,row)
      this.transitionChildIndex = row.id;
      this.imageDetail = row;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-detail {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .detail-left {
    width: 590px;
    height: 100%;
    float: left;
    padding: 220px 145px 105px 145px;
    font-family: Microsoft YaHei;
    // background-color: rgba(255,0,0,.1);
    // .detail-left-col {
    //   width: 100%;
    //   height: 50px;
    //   line-height: 50px;
    //   font-size: 24px;
    //   font-family: SourceHanSansSC;
    //   font-weight: 500;
    //   color: #0015FF;
    //   opacity: 0.96;
    // }
    // .detail-left-col:hover {
    //   cursor: pointer;
    //   opacity: 1;
    // }
    overflow: auto;
    /deep/ .el-collapse-item__header {
      height: 50px;
      line-height: 50px;
      font-size: 22px;
      font-weight: 600;
      font-family: SourceHanSansSC;
      color: #0015FF;
      opacity: 0.96;
    }
    /deep/ .el-collapse-item__content {
      padding-bottom: 0;
    }
    /deep/ .el-collapse-item__arrow{
      display: none;
    }
    /deep/ .el-collapse-item__header {
      justify-content: space-between;
    }
    .downArrow1{
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("@/assets/Icons/add.png");
      background-size: 100% 100%;
    }
    .downArrow2{
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("@/assets/Icons/reduce.png");
      background-size: 100% 100%;
      transform: rotate(-180deg);
      transition: .7s;
    }
    .spare-parts {
      min-height: 100px;
      width: 100%;
      .spare-parts-name {
        width: 100%;
        height: 50px;
        border-top: 1px solid rgba(170, 170, 171,.2);
        font-size: 14px;
        line-height: 50px;
        font-family: SourceHanSansSC;
        // font-weight: 500;
        color: rgba(128, 128, 128, 1);
        .spare-parts-name-text:hover {
          color: #4fbf35;
          cursor: pointer;
        }
      }
    }
  }
  .detail-right {
    width: calc(100% - 590px);
    height: 100%;
    float: left;
    // background-color: yellow;
    .transition-box {
      width: 100%;
      height: 100%;
      background-color: #dbd9da;
      .big-image {
        width: 100%;
        height: calc(100% - 190px);
        position: relative;
        // padding: 15% 0 0 15%;
        padding: 9% 0 0 9%;
        .big-image-content {
          height: 88%;
          width: auto;
        }
        .image-name {
          position: absolute;
          left: 7%;
          top: 20%;
          font-size: 35px;
          font-family: SourceHanSansSC;
          font-weight: bold;
          color: #0015FF;
          opacity: 0.96;
        }
        .little-image-1 {
          width: 95px;
          height: 95px;
          position: absolute;
          top: 14%;
          left: 41%;
          background-image: url("@/assets/Icons/product-2-1.png");
          background-size: 100% 100%;
        }
        .little-image-2 {
          width: 95px;
          height: 95px;
          position: absolute;
          top: 30%;
          left: 41%;
          background-image: url("@/assets/Icons/product-2-2.png");
          background-size: 100% 100%;
        }
        .little-image-3 {
          width: 203px;
          height: 107px;
          position: absolute;
          top: 52%;
          left: 50%;
          background-image: url("@/assets/Icons/product-2-3.png");
          background-size: 100% 100%;
        }
        .little-image-4 {
          width: 203px;
          height: 107px;
          position: absolute;
          top: 68%;
          left: 50%;
          background-image: url("@/assets/Icons/product-2-4.png");
          background-size: 100% 100%;
        }
      }
      .image-bottom {
        width: 100%;
        height: 190px;
        .image-bottom-descript {
          width: 50%;
          height: 100%;
          float: left;
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #AAAAAB;
          line-height: 27px;
          padding: 44px 44px 67px 67px;
        }
        .image-bottom-btn {
          width: 50%;
          height: 100%;
          float: left;
          padding-top: 30px;
          .cl-btb {
            width: 166px;
            height: 45px;
            line-height: 45px;
            font-size: 22px;
            text-align: center;
            border: 2px solid #0015FF;
            color: #0015FF;
            margin: 0 auto;
            border-radius: 25px;
          }
          .cl-btb:hover {
            color: #fff;
            background-color: #0015FF;
            cursor: pointer;
          }
        }
      }
    }
    .table-box {
      width: 100%;
      height: 100%;
      padding: 15% 0 0 7%;
      .table-box-content {
        width: 75%;
        height: 64%;
        img {
          height: 100%;
        }
      }
      .table-box-btn {
        height: 150px;
        width: 70%;
        text-align: right;
        padding-top: 100px;
      }
    }
  }
}
.select-part {
  color: #4fbf35 !important;
  font-weight: 600;
}
.name1 {
  font-size: 22px;
  font-weight: 600;
  font-family: Microsoft YaHei;
  color: #0015FF;
  opacity: 0.96;
}
.name2 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  color: #0015FF;
  opacity: 0.8;
}
.name3 {}
.name4 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: rgba(128, 128, 128, 1);
  cursor: pointer;
}
</style>