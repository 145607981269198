<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      transitionName: 'scale-slide',
    }
  },
  created() {
    // window.localStorage.getItem("language") == null ? 'zh' : window.localStorage.getItem("language"),
    console.log(999,localStorage.getItem("language"))
    if(!localStorage.getItem("language")) {
      localStorage.setItem("language",'en')
    }
  },
  watch: {
    $route(to, from) {
      if(to.meta.index > from.meta.index) {
        this.transitionName = 'scale-slide-right'
      }else{
        this.transitionName = 'scale-slide'
      }
    }
  }
}
</script>
<style>
@import '@/style/index.css';
#app {
  width: 100%;
  /* overflow: hidden; */
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  box-sizing: border-box;
}
/* body {
  margin: 0;
} */

nav {
  /* padding: 30px; */
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
html {
  background-color: #000080;
}
</style>
<style>
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
  overflow: hidden;
}

.scale-slide-enter {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
}
/* --- */
.scale-slide-right-enter-active,
.scale-slide-right-leave-active {
  position: absolute;
  transition: all 0.85s ease;
  overflow: hidden;
}

.scale-slide-right-enter {
  right: -100%;
}

.scale-slide-right-enter-to {
  right: 0%;
}

.scale-slide-right-leave {
  transform: scale(1);
}

.scale-slide-right-leave-to {
  transform: scale(0.8);
}
</style>