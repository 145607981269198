<template>
  <div v-loading="isLoading" class="contacts">
    <div class="contacts-card contacts-1">
      <div class="contacts-logo" @click="handleReturnHome"></div>
      <div class="contacts-btns" @click="handleShowMenu"></div>
      <!-- <div class="contacts-left-and-right">
        <div class="left-arrow" @click="handlePrevRouter"></div>
        <div class="right-arrow" @click="handleNextRouter"></div>
      </div> -->
      <div class="contacts-map">
        <baidu-map :style="{ width:map.width,height:map.height }" class="map" ak="12yiGNPdYCMhV8LGhDEerCnL2kaVdG2i" :zoom="map.zoom" :center="{lng: map.center.lng, lat: map.center.lat}" @ready="handler" :scroll-wheel-zoom="true">
          <!--比例尺控件-->
          <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
          <!--缩放控件-->
          <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" ></bm-navigation>
          <!--聚合动态添加的点坐标-->
          <!-- <bm-marker-clusterer :averageCenter="true">
            <bm-marker v-for="marker of markers" :key="marker.code" :position="{lng: marker.lng, lat: marker.lat}" @click="lookDetail(marker)"></bm-marker>
          </bm-marker-clusterer> -->
          <!--点-->
          <bm-marker
            :position="map.center"
            :dragging="map.dragging"
            animation="BMAP_ANIMATION_DROP"
          >
            <!--提示信息-->
            <bm-info-window
              :show="map.show"
              style="
                text-align: center;
                line-height: 30px;
                font-size: 16px;
                color: #606060;
              "
            >
              <div style="font-weight: 600; margin-top: 10px">
                {{ $t("bilingual0087") }}{{ $t("bilingual0088") }}
              </div>
              <div>{{ $t('bilingual0081') }}</div>
            </bm-info-window>
          </bm-marker>
          <!--信息窗体-->
          <!-- <bm-info-window :position="{lng: infoWindow.info.lng, lat: infoWindow.info.lat}" :title="infoWindow.info.name" :show="infoWindow.show" @close="infoWindowClose" @open="infoWindowOpen">
            <p><span class="left">单位面积能耗：</span><span class="right">{{infoWindow.info.areaEnergy}}kWh/㎡</span></p>
            <p><span class="left">建筑面积：</span><span class="right">{{infoWindow.info.area}}㎡</span></p>
            <p><span class="left">电耗：</span><span class="right">{{infoWindow.info.energy}}kWh</span></p>
            <p><span class="left">水耗：</span><span class="right">{{infoWindow.info.water}}m³</span></p>
            <p><span class="left">气耗：</span><span class="right">{{infoWindow.info.air}}m³</span></p>
          </bm-info-window> -->
      </baidu-map>
      </div>
      <div class="contacts-form">
        <div class="contacts-form-col1">{{ $t("bilingual0059") }}</div>
        <div class="contacts-form-col2" style="margin-bottom: 5px;">{{ $t("bilingual0087") }}</div>
        <!-- <div class="contacts-form-col2">MARINETECH</div> -->
        <div class="contacts-form-col2" style="margin-bottom: 8%;">{{ $t("bilingual0088") }}</div>
        <div class="contacts-form-col3">{{ $t('bilingual0082') }}: {{ contactsBody.email }}</div>
        <div class="contacts-form-col3">{{ $t('bilingual0083') }}: {{ contactsBody.phone }}</div>
        <div class="contacts-form-col4"></div>
        <div class="contacts-form-col5">{{ $t('bilingual0077') }}</div>
        <div class="contacts-form-col5" style="margin-bottom: 4%;">{{ $t('bilingual0078') }}</div>
        <!-- <div class="contacts-form-col5">{{ $t('bilingual0084') }}: {{ contactsBody.site_name }}</div> -->
      </div>
      <div class="contacts-footer"></div>
    </div>
    <MyFooter />
    <Menu ref="menu" />
  </div>
</template>
<script>
import { debounce } from "@/utils"
import MyFooter from "@/components/MyFooter.vue"
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmScale from 'vue-baidu-map/components/controls/Scale'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
import BmMarkerClusterer from  'vue-baidu-map/components/extra/MarkerClusterer'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'
import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow'
import Menu from "@/components/menu.vue"
export default {
  name: 'contacts',
  components: {
    MyFooter,BaiduMap,BmScale,BmNavigation,BmMarkerClusterer,BmMarker,BmInfoWindow,Menu
  },
  data() {
    return {
      isLoading: false,
      contactsBody: {},
      itemIndex: 0,
      about5ImageWidth: 0,
      infoWindow: {
        show: false,
        info: null,
      },
      activeName: '',
      map: {
        center: {
          lng: 116.70035,
          lat: 45.952778,
        },
        zoom: 12,
        width: '100%',
        height: '100%',
        dragging: true,
        show: true,
      },
      markers: [{
        lng: 116.70035,
        lat: 45.952778,
      }],
    }
  },
  computed: {
    screenHeight() {
      var clientHeight = 0;
      if(document.body.clientHeight&&document.documentElement.clientHeight){
        clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }else{
        clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      return clientHeight;
    },
  },
  created() {
    this.doInit();
  },
  mounted() {
    // debounce
    window.addEventListener('wheel', debounce(this.handleMouseWheel,100,false), {
      passive: false,
    })
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.handleMouseWheel, {
      passive: false,
    })
  },
  methods: {
    doInit() {
      this.isLoading = true;
      this.axios({
        url: '/api/index//getList',
        method: 'get',
        params: {
          category: '5',
          lang:  (localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"),
        }
      }).then(resp => {
        let res = resp.data.data;
        console.log(106, res)
        this.contactsBody = {
          email: res.email,
          phone: res.phone,
          site_name: res.site_name,
        }
        this.map.center = {
          lng: res.lng,
          lat: res.lat,
          // lng: 116.70035,
          // lat: 45.952778
        }
        // this.markers = [{
        //   lng: res.lng,
        //   lat: res.lat,
        // }]
        this.isLoading = false;
      })
    },
    handler() {},
    lookDetail(data, target){
      this.infoWindow.show = true;
      this.infoWindow.info = data;
      this.activeName = data.name;
      //为弹窗口标题添加title
      this.$nextTick(()=>{
        var win=document.querySelector(".BMap_bubble_title");
        win.title = this.activeName;
      })
      if(target=='left'){ //点击的是左侧列表项，则不需要滚动
        this.map.center={lng: data.lng, lat: data.lat};
        this.map.zoom = 15;
        return;
      }
      //滚动到指定元素位置
      this.$nextTick(()=>{
        var obj=document.querySelector(".active");
        var scrollTop=obj.offsetTop;
        this.$refs.box.scrollTop=scrollTop-180;
      })
    },
    handleMouseWheel(e) {
      if (!window.scrollY) {
        if (e.wheelDelta) {
          // 判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) {
            this.handlePrev()
          }
          if (e.wheelDelta < 0) {
            // 当滑轮向下滚动时
            this.handleNext()
          }
        } else if (e.detail) {
          // Firefox滑轮事件
          if (e.detail > 0) {
            // 当滑轮向下滚动时
            this.handleNext();
          }
          if (e.detail < 0) {
            // 当滑轮向上滚动时
            this.handlePrev()
          }
        }
      }
    },
    handleNext() {
      this.$refs.carouselRef?.next();
    },
    handlePrev() {
      this.$refs.carouselRef?.prev();
    },
    handleChangeCarousel(newValue,oldValue) {
      this.itemIndex = newValue;
    },
    handlePrevRouter() {
      this.$store.dispatch('myTools/switchRouter', 'contacts').then(res => {
        if(res.prev) {
          this.$router.push({
            path: res.prev
          })
        }
      })
    },
    handleNextRouter() {
      this.$store.dispatch('myTools/switchRouter', 'contacts').then(res => {
        if(res.next) {
          this.$router.push({
            path: res.next
          })
        }
      })
    },
    handleShowMenu() {
      this.$refs.menu.handleShow();
    },
    handleReturnHome() {
      this.$router.push({ path: '/' });
    },
  }
}
</script>
<style lang="scss" scoped>
.contacts {
  width: 100%;
}
.contacts-card {
  width: 100%;
  height: 100vh;
  position: relative;
}
.contacts-logo{
  width: 130px;
  height: 83px;
  position: absolute;
  left: 60px;
  top: 40px;
  background-image: url("@/assets/Icons/logo-blue.png");
  background-size: 100% 100%;
  cursor: pointer;
  z-index: 2;
}
.contacts-btns {
  width: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more-blue3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
}
.contacts-left-and-right {
  width: 200px;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgb(49, 49, 255);
  padding: 0 50px;
  z-index: 3;
  .left-arrow {
    width: 40px;
    height: 40px;
    float: left;
    background-image: url("@/assets/Icons/left-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
  .right-arrow {
    width: 40px;
    height: 40px;
    float: right;
    background-image: url("@/assets/Icons/right-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
}
.contacts-1 {
  .contacts-map {
    width: 50%;
    height: calc(100% - 95px);
    float: left;
  }
  .contacts-form {
    width: 50%;
    height: 100%;
    background-color: #e0dedf;
    float: left;
    padding: 40px 0 0 60px;
    .contacts-form-col1 {
      width: 150px;
      height: 30px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #0015FF;
      line-height: 25px;
      opacity: 0.96;
      margin-bottom: 4%;
    }
    .contacts-form-col2 {
      font-size: 60px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #0015FF;
      line-height: 75px;
      opacity: 0.96;
    }
    .contacts-form-col3 {
      font-size: 24px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #0015FF;
      line-height: 33px;
      margin-bottom: 10px;
    }
    .contacts-form-col4 {
      height: 1px;
      width: 100%;
      border-top: 1px solid #c9c9c9;
      margin-top: 11%;
      margin-bottom: 7%;
    }
    .contacts-form-col5 {
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #707070;
      line-height: 25px;
    }
  }
  .contacts-footer {
    width: 100%;
    height: 95px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgb(49, 49, 255);
  }
}
</style>