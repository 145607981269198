<template>
  <div v-loading="isLoading" class="footer">
    <div class="footer-content1"></div>
    <div class="footer-content2">
      <div class="footer-phone-number">+86 24 8105 5099</div>
      <div class="footer-email-text">{{ $t("bilingual0018") }}</div>
      <div class="footer-email-input">
        <el-input class="footer-email-input-com" v-model="email" :placeholder="$t('bilingual0082')" @keyup.enter.native="handleAddMessage"></el-input>
      </div>
    </div>
    <div class="footer-content1">
      <div class="menus-btns">
        <span class="menus-btns-text" @click="handleJumpRouter('/about')">{{ $t("bilingual0019") }}</span>
      </div>
      <div class="menus-btns">
        <span class="menus-btns-text" @click="handleJumpRouter('/product')">{{ $t("bilingual0020") }}</span>
      </div>
      <div class="menus-btns">
        <span class="menus-btns-text" @click="handleJumpRouter('/partner')">{{ $t("bilingual0021") }}</span>
      </div>
      <div class="menus-btns">
        <span class="menus-btns-text" @click="handleJumpRouter('/news')">{{ $t("bilingual0022") }}</span>
      </div>
      <div class="menus-btns">
        <span class="menus-btns-text" @click="handleJumpRouter('/contacts')">{{ $t("bilingual0023") }}</span>
      </div>
    </div>
    <div class="footer-content2" style="padding-top: 100px;">
      <div class="content-info-col">
        <!-- <span class="content-info-col-text">{{ $t("bilingual0024") }}</span> -->
        <span class="content-info-col-text">{{ $t("bilingual00241") }}{{$t("bilingual0011")}}</span>
      </div>
      <div class="content-info-col">
        <!-- <span class="content-info-col-text">{{ $t("bilingual0025") }}</span> -->
        <!-- <span class="content-info-col-text">{{ footerBody.gwa }}{{ $t("bilingual0087") }}{{ $t("bilingual0088") }}</span> -->
        <span class="content-info-col-text">{{ $t("bilingual0087") }}{{ $t("bilingual0088") }}</span>
      </div>
      <div class="content-info-col">
        <span class="content-info-col-text">{{ $t("bilingual0089") }}: {{ footerBody.email }}</span>
        <!-- <span class="content-info-col-text">{{ $t("bilingual0026") }}</span> -->
      </div>
      <div class="content-info-col">
        <span class="content-info-col-text">{{ $t("bilingual0027") }}</span>
      </div>
      <!-- <div class="content-info-col" style="text-align: center;padding-right:400px;">
        <span class="content-info-col-text">{{ $t("bilingual0059") }}</span>
      </div> -->
      <div class="contract-us" @click="handleJunp">
        <div style="width: 38px; margin-left: 27px; margin-top: 7px;float: left;">{{ $t("bilingual0023") }}</div>
        <img src="@/assets/Icons/footer-btn.png" style="width: 60px;height: 53px;float: left;margin-top: 3px;margin-left: 11px;" alt="">
      </div>
      
    </div>
    <div class="footer-content3">
      <!-- <span class="bottom-text">{{ $t("bilingual0012") }}</span> -->
      <!-- <span class="bottom-text">{{ footerBody.icp }}</span> -->
      <a class="bottom-text" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{ footerBody.icp }}</a>
      <!-- <span class="bottom-text">{{ $t("bilingual0013") }}</span>
      <span class="bottom-text">{{ $t("bilingual0014") }}</span>
      <span class="bottom-text">{{ $t("bilingual0015") }}</span>
      <span class="bottom-text">Cookies</span>
      <span class="bottom-text">{{ $t("bilingual0016") }}</span>
      <span class="bottom-text">{{ $t("bilingual0017") }}</span> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'myFooter',
  data() {
    return {
      email: '',
      isLoading: false,
      footerBody: {},
    }
  },
  computed: {
    routerName() {
      return this.$route.path
    }
  },
  created() {
    this.isLoading = true;
    // addMessage
    this.axios({
      url: '/api/index//getList',
      method: 'get',
      params: {
        category: '5',
        lang:  (localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"),
      }
    }).then(resp => {
      let res = resp.data.data;
      console.log(82, res)
      this.footerBody = Object.assign({},res)
      this.isLoading = false;
    })
  },
  methods: {
    handleJumpRouter(path) {
      if(this.routerName != path) {
        this.$router.push({
          path: path
        })
      }
    },
    handleAddMessage() {
      this.axios({
        url: '/api/index//addMessage',
        method: 'get',
        params: {
          text: this.email
        }
      }).then(resp => {
        console.log(resp)
        if(resp.status == 200) {
          alert(resp.data.msg)
        }
      })
    },
    handleJunp() {
      this.$router.push({
        path: '/contacts'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 746px;
  background-color: #08234d;
  padding: 72px 55px 0 55px;
  position: relative;
  .footer-content1 {
    width: 400px;
    height: 275px;
    float: left;
    padding-top: 70px;
    // background-color: red;
    .menus-btns {
      font-size: 14px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      
      line-height: 30px;
      .menus-btns-text {
        color: #D8D8D8;
      }
      .menus-btns-text:hover {
        cursor: pointer;
        text-decoration:underline;
        color: #ffffff;
      }
    }
  }
  .footer-content2 {
    width: calc(100% - 400px);
    height: 275px;
    float: left;
    position: relative;
    // background-color: yellow;
    .footer-phone-number {
      height: 40px;
      width: 100%;
      line-height: 40px;
      font-size: 48px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 115px;
    }
    .footer-email-text {
      height: 30px;
      width: 100%;
      line-height: 30px;
      font-size: 24px;
      font-family: SourceHanSansSC;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 20px;
    }
    .footer-email-input {
      width: 800px;
      height: 60px;
      // background-color: red;
      .footer-email-input-com {
        width: 100%;
        height: 100%;
      }
      /deep/ .el-input__inner {
        height: 100%;
        font-size: 18px;
      }
      /deep/ .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
        background-color: #001f3e;
      }
    }
    .content-info-col {
      font-size: 14px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      
      line-height: 30px;
      .content-info-col-text {
        color: #D8D8D8;
      }
      .content-info-col-text:hover {
        cursor: pointer;
        text-decoration:underline;
        color: #ffffff;
      }
    }
    .contract-us {
      width: 158px;
      height:158px;
      position: absolute;
      right: 0;
      bottom: 0;
      //background-color: #0015FF;
      color: #fff;
      border-radius: 85px;
      // line-height: 158px;
      text-align: center;
      cursor: pointer;
      padding-top: 50px;
    }
  }
  .footer-content3 {
    width: calc(100% - 110px);
    height: 90px;
    line-height: 90px;
    position: absolute;
    bottom: 1px;
    left: 55px;
    border-top: 1px solid #fff;
    font-size: 14px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
    .bottom-text {
      margin-right: 20px;
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>