export default {
    bilingual0001: '关于我们',
    bilingual0002: '我们组建了一支专业的专家团队，提供最好的疏浚和油田解决方案。',
    bilingual0003: '团队、员工和信誉良好的供应商都有一个共同点——对产品和市场的热情。我们也将客户视为团队的重要组成部分。',
    bilingual0004: '品牌故事',
    bilingual0005: '2016年——十几个怀揣梦想的橡胶工程师，在国企中走出来，离开舒适的环境，走在了创业的路上。公司设立之初，创始人们定下的发展方向就是海洋工程用特种胶橡胶制品。主要包括橡胶浚管、橡胶输油管等。',
    bilingual0006: '向上',
    bilingual0007: '实验室',
    bilingual0008: '团队、员工和信誉良好的供应商都有一个共同点——对产品和市场的热情。我们也将客户视为团队的重要组成成分。',
    bilingual0009: '企业资质',
    bilingual0010: '团队、员工及信誉良好及认可的供应商。',
    bilingual0011: '证书',
    bilingual0012: '© 2023 版权所有蜀ICP备2021003570号-1',
    bilingual0013: '法律',
    bilingual0014: '商业伦理',
    bilingual0015: '隐私',
    bilingual0016: '可访问性声明',
    bilingual0017: '中国',
    bilingual0018: '请留下您的联系方式，以便我们能更快地与您联系。',
    bilingual0019: '关于我们',
    bilingual0020: '产品介绍',
    bilingual0021: '合作伙伴',
    bilingual0022: '新闻资讯',
    bilingual0023: '联系我们',
    bilingual0024: 'Add: No.5-3 Shenxisandong Road,Econmical&Technical',
    bilingual00241: '地址: ',
    bilingual0025: '辽宁维航基业科技有限公司',
    bilingual0026: '邮箱: joyhou@vh-marinetech.com',
    bilingual0027: '网址: www.vh-marinetech.com',
    bilingual0028: '联系我们',
    bilingual0029: '产品介绍',
    bilingual0030: '矿用胶管',
    bilingual0031: '疏浚胶管',
    bilingual0032: '输油胶管',
    bilingual0033: '新产品',
    bilingual0034: '数据表格',
    bilingual0035: '浮动软管管道是一个完整的系统，具有极大的灵活性和直接连接在挖泥船后面。通常这种管道适合大海浪和极端困难的条件工作。',
    bilingual0036: 'Floating HOSE',
    bilingual0037: '合作伙伴',
    bilingual0038: '我们的产品参与了世界各地的重大项目',
    bilingual0039: '案例实景',
    bilingual0040: '详情',
    bilingual00411: '85根730x11800油砂漂浮管',
    bilingual00412: '加拿大油砂管项目',
    bilingual00421: '30根1000x11800铠装',
    bilingual00422: '漂浮胶管',
    bilingual00431: '17根20寸及16寸双骨架PU外',
    bilingual00432: '覆层输油胶管',
    bilingual00441: '18根20寸及16寸双骨架PU外',
    bilingual00442: '覆层输油胶管',
    bilingual0050: '新闻资讯',
    bilingual0051: '你可以看到下面的一些主要项目，我们的软管和相关的疏浚和石油设备都被用作主要的建筑部件。',
    bilingual0052: '我们甚至提供现场监测服务，以确保提供最佳的解决方案。',
    bilingual0053: '荷兰',
    bilingual0054: '团队、员工和信誉良好的供应商都有一个共同点——对产品和市场的热情。我们也将客户视为团队的重要组成部分。我们组建了一支专业的专家团队，提供最好的疏浚和油田解决方案。',
    bilingual0055: '新加坡',
    bilingual0056: '美国',
    bilingual0057: '深圳海事展',
    bilingual0058: '我们的活动',
    bilingual0059: '联系我们',
    bilingual0060: '中文',
    bilingual0061: '英文',
    bilingual0062: '公司简介',
    bilingual0063: '实验室',
    bilingual0064: '荣誉资质',
    bilingual0065: '参加的展会',
    bilingual0066: '矿用胶管',
    bilingual0067: '疏浚胶管',
    bilingual0068: '输油胶管',
    bilingual0069: '新产品',
    bilingual0070: '',
    bilingual0071: '服务',
    bilingual0072: '我们的产品参与了世界各地的重大项目',
    bilingual0073: '你可以看到下面的一些主要项目，我们的软管和相关的疏没和石油设备被用作主要的建筑部件。',
    bilingual0074: '我们甚至提供现场监测服务，以确保提供最佳的解决方案。',
    bilingual0075: '有限元分析',
    bilingual0076: '压力模拟',
    bilingual0077: '地址: 辽宁省沈阳经济技术开发区沈西三东路5-3号',
    bilingual0078: '',
    bilingual0079: '电子邮件: joyhou@vh-marinetech.com',
    bilingual0080: '网站地址: www.vh-marinetech.com',
    bilingual0081: '辽宁省沈阳经济技术开发区沈西三东路5-3号',
    bilingual0082: '电子邮件',
    bilingual0083: '联系电话',
    bilingual0084: '网站地址',
    bilingual0085: '其他橡胶制品',
    bilingual0086: '更多内容',   
    bilingual0087:'辽宁维航基业科技',
    bilingual0088:'有限公司',
    bilingual0089:'邮箱',
    HOME:'首页',
    bilingual0010:'35根900×11800铠装自浮管',
    bilingual0011:'辽宁省沈阳经济技术开发区沈西二东路5-3号',
}