import Vue from'vue'// 提交验证

Vue.directive('clickOutside', {
  bind(el, binding, vnode){
    function functionclickHandler(e) {
      if (el.contains(e.target)) { 
        return false; 
      } 
     // 判断指令中是否绑定了函数
    if (binding.expression){
      binding.value(e);
    }
  }
  el.__vueClickOutside__ = functionclickHandler; 
  document.addEventListener('click', functionclickHandler); 
  },
  update() {}, 
  unbind(el, binding) { 
    document.removeEventListener('click', el.__vueClickOutside__); 
    delete el.__vueClickOutside__; 
  },
});