import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ProductView from '../views/ProductView.vue'
import PartnerView from '../views/PartnerView.vue'
import NewsView from '../views/NewsView.vue'
import ContactsView from '../views/ContactsView.vue'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    meta:{ index: 0 },
    component: AboutView
  },
  {
    path: '/product',
    name: 'product',
    meta:{ index: 1 },
    component: ProductView
  },
  {
    path: '/partner',
    name: 'partner',
    meta:{ index: 2 },
    component: PartnerView
  },
  {
    path: '/news',
    name: 'news',
    meta:{ index: 3 },
    component: NewsView
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta:{ index: 4 },
    component: ContactsView
  },
  {
    path: '/home',
    name: 'home',
    meta:{ index: 5 },
    component: Home
  },
]

var router = new VueRouter({
  routes
})
export default router
