export default {
    bilingual0001: 'Offshore Supply Design And Experts',
    bilingual0002: 'A professional team of experts is assembled to provide the best dredging and oilfield solutions to our partners.',
    bilingual0003: 'Teams,employees and reputable and recognized suppliers all have one thing in common - enthusiasm for prodcuts and markets. We alse customers as an important part of team.',
    bilingual0004: 'Brand Story',
    bilingual0005: `2016 - A dozen rubber engineers with a dream, walked out of state-owned enterprises, left their comfortable environment and went on to start their own business. At the beginning of the company's establishment, the founders set the direction of development as special rubber rubber products for marine engineering. The main products include rubber dredging hoses and rubber oil hoses.`,
    bilingual0006: 'UP',
    bilingual0007: 'Study Ability',
    bilingual0008: `Teams, employees and reputable and recognized suppliers all have one thing in common - enthusiasm for products and markets. We also regard customers as an important part of the team`,
    bilingual0009: 'HONORARY QUALIFICATIONS',
    bilingual0010: 'Teams, employees and reputable and recognized suppliers',
    bilingual0011: 'CERTIFICATE',
    bilingual0012: '© 2023 版权所有蜀ICP备2021003570号-1',
    bilingual0013: '法律',
    bilingual0014: '商业伦理',
    bilingual0015: '隐私',
    bilingual0016: '可访问性声明',
    bilingual0017: '中国',
    bilingual0018: 'Leave Your Contact Information So That We Can Contact You More Quickly',
    bilingual0019: 'ABOUT US',
    bilingual0020: 'OUR PRODUCTS',
    bilingual0021: 'OUR CUSTOMERS',
    bilingual0022: 'NEWS',
    bilingual0023: 'CONTACT US',
    bilingual0024: 'Add: No.5-3 Shenxisandong Road,Econmical&Technical',
    bilingual00241: 'Add: ',
    bilingual0025: 'Developent Zone,Shenyang,China',
    bilingual0026: 'Email: joyhou@vh-marinetech.com',
    bilingual0027: 'Website: www.vh-marinetech.com',
    bilingual0028: 'CONTACT US',
    bilingual0029: 'Product introduction',
    bilingual0030: 'Mining Hose',
    bilingual0031: 'Dredging Hose',
    bilingual0032: 'Oil Delivery Hose',
    bilingual0033: 'New Product',
    bilingual0034: 'Data Tables',
    bilingual0035: 'Floating Hose Pipeline Is Whole System,it Has Extreme Fiexibility And Dir Rect Connect Behind Dredger.normally This Type Pipeline Suitable Big Sea Wave And Exreme Difficut Working Conditions',
    bilingual0036: 'Floating HOSE',
    bilingual0037: 'The latest case',
    bilingual0038: 'Our products have participated in major projects around the world',
    bilingual0039: 'Global Services',
    bilingual0040: 'Details',
    bilingual00411: '85pcs ID750×L11800mm',
    bilingual00412: 'Oil Sand Floating Hose',
    bilingual00421: '30pcs ID1000×L11800mm',
    bilingual00422: 'Armoured Floating Hose',
    bilingual00431: '17 pcs ID20inch×L35feet &ID16inch×L35feet Double',
    bilingual00432: 'Carcass Floating Oil Hose with PU Cover',
    bilingual00441: '18 pcs  ID20inch×L35feet &ID16inch×L35feet Double',
    bilingual00442: 'Carcass Floating Oil Hose with PU Cover',
    bilingual0050: 'News',
    bilingual0051: 'You can see some of the major projects below, where our hoses and associated dredging and oil equipment are used as major building components.',
    bilingual0052: 'We even provide on-site monitoring services to ensure that the best solution is provided.',
    bilingual0053: 'Netherlands',
    bilingual0054: 'Teams,employees and reputable and recognized suppliers all have one thing in common - enthusiasm for prodcuts and markets. We alse customers as an important part of team.A professional team of experts is assembled to provide the best dredging and oilfield solutions to our partners.',
    bilingual0055: 'Singapore',
    bilingual0056: 'America',
    bilingual0057: 'Shenzhen Maritime Exhibition',
    bilingual0058: 'Our Activities',
    bilingual0059: 'Contact Us',
    bilingual0060: 'Chinese',
    bilingual0061: 'English',
    bilingual0062: 'Company Profile',
    bilingual0063: 'Study Ability',
    bilingual0064: 'Honorary qualifications',
    bilingual0065: 'Attend The Exhibition',
    bilingual0066: 'MINING HOSE',
    bilingual0067: 'DREDGING HOSE',
    bilingual0068: 'OIL DELIVERY HOSE',
    bilingual0069: 'NEW PRODUCT',
    bilingual0070: 'HOSELINE SYSTEM',
    bilingual0071: 'Service',
    bilingual0072: 'Our products have participated in major projects around the world',
    bilingual0073: 'You can see some of the major projects below, where our hoses and associated dredging and oil equipment are used as major building components.',
    bilingual0074: 'We even provide on-site monitoring services to ensure that the best solution is provided.',
    bilingual0075: 'Finite Element Analysis',
    bilingual0076: 'Pressure simulation',
    bilingual0077: 'Add: No. 5-3, Shenxisandong Road, Economic and Technological',
    bilingual0078: 'Development Zone, Shenyang, China',
    bilingual0079: 'Email: joyhou@vh-marinetech.com',
    bilingual0080: 'Website: www.vh-marinetech.com',
    bilingual0081: 'No. 5-3, Shenxisandong Road, Economic and Technological Development Zone, Shenyang, China',
    bilingual0082: 'Email',
    bilingual0083: 'TEL',
    bilingual0084: 'Website',
    bilingual0085: 'OTHER RUBBER PRODUCTS',
    bilingual0086: 'more information',
    bilingual0087:'VH-MARINETECH',
    bilingual0088:'CO.,LTD',
    bilingual0089:'Email',
    bilingual0010:'35pcs ID900×L11800mm Armoured Floating Hose',
    bilingual0011:'No.5-3 Shenxisandong Road,Econmical and Technical Developent Zone,Shenyang,China',
}
