<template>
  <div v-loading="isLoading" class="partner">
    <el-carousel ref="carouselRef" :initial-index="0" class="myCarousel" :height="screenHeight+'px'" direction="vertical" :loop="false" :autoplay="false" @change="handleChangeCarousel">
      <el-carousel-item name="0">
        <div class="about-card about-1">
          <div class="about-logo" @click="handleReturnHome"></div>
          <div class="about-btns" @click="handleShowMenu"></div>
          <div class="about-next-card" @click="handleNext">
          </div>
          <div class="partner-1-image">
            <!-- <img src="@/assets/BigImage/partner-1-1.jpg" alt="" style="width:100%;height:100%;"> -->
            <img :src="partner01.image" alt="" style="width:100%;height:100%;">
          </div>
          <div class="partner-1-left">
            <div id="partnertitle">
              <!-- <span>{{ $t("bilingual0037") }}</span> -->
              <span>{{ partner01?.name }}</span>
            </div>
            <div class="about-title-base"></div>
            <div id="partner1desc" class="partner-1-left-2">
              <!-- <span>{{ $t("bilingual0038") }}</span> -->
              <span>{{ partner01?.desc }}</span>
            </div>
            <div class="partner-1-left-3">3000+</div>
            <div class="partner-1-left-4">
              <span>{{ $t("bilingual0039") }}</span>
            </div>
          </div>
          <div class="partner-1-right"></div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="1">
        <div class="about-card about-2">
          <div class="about-logo2" @click="handleReturnHome"></div>
          <div class="about-btns2" @click="handleShowMenu"></div>
          <div class="about-next-card" @click="handleNext"></div>
          <div class="partner-2-content">
            <div class="partner-2-leftbtn">
              <img src="@/assets/Icons/about5-left.png" alt="" @click="handleP2Action('left')">
            </div>
            <div class="partner-2-cards">
              <div id="cards-content" class="cards-content">
                <div v-for="(item, index) in partner2Arr" :key="item.id" class="partner-2-cards-card" :class="{ 'partner-2-cards-card-rightline': (index+1) < partner2Arr.length }">
                  <div class="card-main">
                    <div class="card-main-month">{{ $t(item.month) }}</div>
                    <div class="card-main-desc">{{ $t(item.desc) }}</div>
                    <div class="card-main-projectName">{{ $t(item.projectName) }}</div>
                  </div>
                  <div class="card-logo">
                     <el-image class="show-image-name" :src="item.logo" alt="" style="width: 188px;height: 58px;margin-top: 25px;" fit="contain"></el-image>
                  </div>
                  <div class="card-btn">
                    <el-button class="cl-card-btn" type="primary" round plain style="width: 105px;">{{ $t("bilingual0040") }}</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="partner-2-rightbtn">
              <img src="@/assets/Icons/about5-right.png" alt="" @click="handleP2Action('right')">
            </div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="2">
        <div class="about-card about-3">
          <div class="about-logo2" @click="handleReturnHome"></div>
          <div class="about-btns2" @click="handleShowMenu"></div>
          <div class="partner-3-content">
            <div class="partner-3-leftbtn">
              <!-- <img src="@/assets/Icons/about5-left.png" alt="" @click="handleP3Action('left')"> -->
            </div>
            <div class="partner-3-cards">
              <el-carousel ref="partner3carousels" class="partner3carousels" :interval="5000" arrow="always">
                <el-carousel-item v-for="item in partner3Arr" :key="item">
                  <img :src="item" alt="" style="width:100%;height:100%;">
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="partner-3-rightbtn">
              <!-- <img src="@/assets/Icons/about5-right.png" alt="" @click="handleP3Action('right')"> -->
            </div>
            <!-- <div class="partner-3-columns">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo1.png" alt="" style="width:141px;height:50px;margin-right:82px;margin-bottom:21px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo2.png" alt="" style="width:74px;height:75px;margin-right:85px;margin-bottom:10px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo3.png" alt="" style="width:117px;height:87px;margin-right:101px;margin-bottom:5px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo4.png" alt="" style="width:97px;height:84px;margin-right:89px;margin-bottom:6px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo5.png" alt="" style="width:93px;height:97px;margin-right:91px">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo6.png" alt="" style="width:141px;height:50px;margin-bottom:23px;">
            </div>
            <div class="partner-3-columns">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo21.png" alt="" style="width:136px;height:42px;margin-right:93px;margin-bottom:15px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo22.png" alt="" style="width:145px;height:49px;margin-right:91px;margin-bottom:12px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo23.png" alt="" style="width:178px;height:73px;margin-right:76px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo24.png" alt="" style="width:175px;height:64px;margin-right:93px;margin-bottom:5px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo25.png" alt="" style="width:124px;height:60px;margin-bottom:21px;">
            </div>
             <div class="partner-3-columns">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo31.png" alt="" style="width:137px;height:82px;margin-right:81px;margin-bottom:9px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo32.png" alt="" style="width:195px;height:50px;margin-right:94px;margin-bottom:25px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo33.png" alt="" style="width:127px;height:87px;margin-right:73px;margin-bottom:7px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo34.png" alt="" style="width:200px;height:43px;margin-right:104px;margin-bottom:28px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo35.png" alt="" style="width:100px;height:100px;">
            </div>
            <div class="partner-3-columns">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo41.png" alt="" style="width:108px;height:77px;margin-right:118px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo42.png" alt="" style="width:158px;height:49px;margin-right:96px;margin-bottom:14px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo43.png" alt="" style="width:137px;height:77px;margin-right:111px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo44.png" alt="" style="width:76px;height:77px;margin-right:87px;">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo45.png" alt="" style="width:220px;height:77px;">
            </div> -->
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <MyFooter v-if="itemIndex == 2" />
    <Menu ref="menu" />
  </div>
</template>
<script>
import { debounce } from "@/utils"
import MyFooter from "@/components/MyFooter.vue";
// import partner21 from "@/assets/Logo/logo21.png";
// import logo25 from "@/assets/Logo/logo25.png";
// import partner22 from "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/partner-2-2.png";
import Menu from "@/components/menu.vue"
export default {
  name: 'partner',
  components: {
    MyFooter,Menu
  },
  data() {
    return {
      isLoading: false,
      partner01: null,
      itemIndex: 0,
      about5ImageWidth: 0,
      about5Left: 0,
      partner2Arr: [],
      partner3Arr: [],
      // partner2Arr: [{
      //   id: '1',
      //   month: '2022.7',
      //   desc: this.$t("bilingual00411"),
      //   projectName: this.$t("bilingual00412"),
      //   logo: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo21.png"
      // },{
      //   id: '2',
      //   month: '2022.11',
      //   desc: this.$t("bilingual00421"),
      //   projectName: this.$t("bilingual00422"),
      //   logo: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo25.png"
      // },{
      //   id: '3',
      //   month: '2021.5',
      //   desc: this.$t("bilingual00431"),
      //   projectName: this.$t("bilingual00432"),
      //   logo: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/partner-2-2.png"
      // },{
      //   id: '4',
      //   month: '2020.10',
      //   desc: this.$t("bilingual00441"),
      //   projectName: this.$t("bilingual00442"),
      //   logo: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/partner-2-2.png"
      // },{
      //   id: '5',
      //   month: '2022.7',
      //   desc: this.$t("bilingual00411"),
      //   projectName: this.$t("bilingual00412"),
      //   logo: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo21.png"
      // },{
      //   id: '6',
      //   month: '2022.11',
      //   desc: this.$t("bilingual00421"),
      //   projectName: this.$t("bilingual00422"),
      //   logo: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo25.png"
      // },{
      //   id: '7',
      //   month: '2021.5',
      //   desc: this.$t("bilingual00431"),
      //   projectName: this.$t("bilingual00432"),
      //   logo: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/partner-2-2.png"
      // },{
      //   id: '8',
      //   month: '2020.10',
      //   desc: this.$t("bilingual00441"),
      //   projectName: this.$t("bilingual00442"),
      //   logo: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/partner-2-2.png"
      // },]
    }
  },
  computed: {
    screenHeight() {
      var clientHeight = 0;
      if(document.body.clientHeight&&document.documentElement.clientHeight){
        clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }else{
        clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      return clientHeight;
    },
    //计算.partner-2-cards-card有几个
    partner2ArrLength() {
      return document.querySelectorAll('.partner-2-cards-card').length;
    }
  },
  watch: {
    itemIndex(val1,val2) {
      if(val1 == '0') {
        setTimeout(() => {
          document.getElementById("partnertitle").className="trends-title";
          document.getElementById("partner1desc").className="trends-desc1";
        },300)
      }else{
        console.log(1898)
        document.getElementById("partnertitle").className="partner-1-left-1";
        document.getElementById("partner1desc").className="partner-1-left-2";
      }
    }
  },
  created() {
    this.doInit();
  },
  mounted() {
    // debounce
    this.$nextTick(() => {
      setTimeout(() => {
        document.getElementById("partnertitle").className = "trends-title";
        document.getElementById("partner1desc").className="trends-desc1";
      },300)
    });
    window.addEventListener('wheel', debounce(this.handleMouseWheel,100,false), {
      passive: false,
    })
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.handleMouseWheel, {
      passive: false,
    })
  },
  methods: {
    doInit() {
      this.isLoading = true;
      this.axios({
        url: '/api/index//getList',
        method: 'get',
        params: {
          category: '3',
          lang:  (localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"),
        }
      }).then(resp => {
        let res = resp.data.data;
        console.log(207, res)
        this.partner01 = {
          name: res.name,
          desc: res.description,
          image: res.thumbnail
        }
        this.partner2Arr = res.child[0]?.list.map((item,index) => {
          return {
            id: index + 1,
            month: item.excerpt,
            desc: item.title,
            // projectName: '',
            logo: item.thumbnail,
          }
        })
        this.partner3Arr = res.child[1]?.list.map(item => {
          return item.thumbnail;
        })
        this.isLoading = false;
      })
    },
    handleMouseWheel(e) {
      if (!window.scrollY) {
        if (e.wheelDelta) {
          // 判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) {
            this.handlePrev()
          }
          if (e.wheelDelta < 0) {
            // 当滑轮向下滚动时
            this.handleNext()
          }
        } else if (e.detail) {
          // Firefox滑轮事件
          if (e.detail > 0) {
            // 当滑轮向下滚动时
            this.handleNext();
          }
          if (e.detail < 0) {
            // 当滑轮向上滚动时
            this.handlePrev()
          }
        }
      }
    },
    handleNext() {
      this.$refs.carouselRef?.next();
    },
    handlePrev() {
      this.$refs.carouselRef?.prev();
    },
    handleChangeCarousel(newValue,oldValue) {
      this.itemIndex = newValue;
    },
    handlePrevRouter() {
      this.$store.dispatch('myTools/switchRouter', 'partner').then(res => {
        if(res.prev) {
          this.$router.push({
            path: res.prev
          })
        }
      })
    },
    handleNextRouter() {
      this.$store.dispatch('myTools/switchRouter', 'partner').then(res => {
        if(res.next) {
          this.$router.push({
            path: res.next
          })
        }
      })
    },
    handleShowMenu() {
      this.$refs.menu.handleShow();
    },
    handleReturnHome() {
      this.$router.push({ path: '/' });
    },
    handleP2Action(type) {
      //计算id=cards-content的实际宽度
     //计算.partner-2-cards-card有几个

console.log(292, this.partner2ArrLength)
      var _el = document.getElementById("cards-content");
      if(type == 'left') {
        if(this.about5Left < 0) {
          this.about5Left = this.about5Left + 412;
          _el.style.left = this.about5Left + 'px';
        }
      }else{
        if(this.about5Left > -412 * (this.partner2ArrLength - 4)) {
          this.about5Left = this.about5Left - 412;
          _el.style.left = this.about5Left + 'px';
        }
       
      }
    },
    handleP3Action(type) {
      if(type == 'left') {
        this.$refs.partner3carousels.prev();
      }else{
        this.$refs.partner3carousels.next();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// @import "@style/c.css";
// @import "@style/s.css";
// @import "@style/v.css";
.partner {
  width: 100%;
}
.about-card {
  width: 100%;
  height: 100vh;
  position: relative;
}
.about-logo {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-write.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.about-logo2 {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-blue.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.about-btns {
  width: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
}
.about-btns2 {
  width: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more-blue3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
}
.about-next-card {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn.png");
  background-size: 100% 100%;
  opacity: .4;
  z-index: 9;
  
}
.about-next-card:hover {
  cursor: pointer;
  opacity: .9;
}
.about-next-card2 {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn2.png");
  background-size: 100% 100%;
  opacity: .4;
  z-index: 9;
}
.about-next-card2:hover {
  cursor: pointer;
  opacity: .9;
}
.about-1 {
  // background-image: url("@/assets/BigImage/about-1-1.jpg");
  background-size: 100% 100%;
  .partner-1-left {
    width: 60%;
    height: 100%;
    float: left;
    background-color: #0515ee;
    padding-top: 14%;
    padding-left: 13%;
    .partner-1-left-1 {
      font-size: 100px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 100px;
      width: 100%;
      height: 110px;
      margin-bottom: 33px;
    }
    .partner-1-left-2 {
      font-size: 24px;
      font-family: SourceHanSansSC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 31px;
      width: 243px;
      height: 110px;
      margin-top: 6%;
      padding-left: 10px;
    }
    .partner-1-left-3 {
      width: 100%;
      height: 110px;
      margin-top: 15%;
      font-size: 100px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 100px;
    }
    .partner-1-left-4 {
      font-size: 24px;
      font-family: SourceHanSansSC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 31px;
      margin-top: 15px;
      margin-left: 6px;
    }
  }
  .partner-1-right {
    width: 40%;
    height: 100%;
    float: left;
    background-color: #071f43;
  }
  .partner-1-image {
    position: absolute;
    right: 0;
    bottom: 10%;
    height: 76%;
    width: 47%;
    // background-image: url("@/assets/BigImage/partner-1-1.jpg");
    background-size: 100% 100%;
    transition: all 0.4s;
  }
  .partner-1-image:hover {
    // box-shadow: 0 8px 8px 0 grey;
    transform: scale(1.1,1.1);
  }
}
.about-2 {
  background-color: #e0dedf;
  position: relative;
  padding-top: 7%;
  .partner-2-content {
    width: 100%;
    height: 86%;
    position: relative;
    .partner-2-leftbtn {
      width: 135px;
      height: 100%;
      float: left;
      padding-left: 30px;
      padding-top: 16%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      img {
        width: 75px;
        height: 46px;
        cursor: pointer;
      }
    }
    .partner-2-cards {
      width: 1648px;
      height: 100%;
      // float: left;
      // background-color: red;
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      .cards-content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 20000px;
        overflow: hidden;
        transition: 1s;
        .partner-2-cards-card {
          width: 412px;
          height: 100%;
          float: left;
          box-sizing: border-box;
          .card-main {
            width: 100%;
            height: 50%;
            padding: 15% 19%;
            .card-main-month {
              font-size: 36px;
              font-family: SourceHanSansSC;
              font-weight: bold;
              color: #0015FF;
              line-height: 95px;
              opacity: 0.96;
            }
            .card-main-desc {
              font-size: 20px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #AAAAAB;
              line-height: 30px;
            }
            .card-main-projectName {
              font-size: 20px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #AAAAAB;
              line-height: 30px;
            }
          }
          .card-main:hover {
            cursor: pointer;
            background-image: url("@/assets/BigImage/partner-2-bg.jpg");
            background-size: 100% 100%;
          }
          .card-main:hover .card-main-month{
            color: #ffffff;
          }
          .card-main:hover .card-main-desc{
            color: #ffffff;
          }
          .card-main:hover .card-main-projectName{
            color: #ffffff;
          }
          .card-logo {
            width: 100%;
            height: calc(50% - 100px);
            text-align: center;
            padding-top: 20%;
            img {
              margin: 0 auto;
              height: 50%;
            }
          }
          .card-btn {
            width: 100%;
            height: 100px;
            text-align: center;
            padding-top: 0px;
            /deep/ .el-button--primary.is-plain {
              background: #e0dedf;
              border: 1px solid #0015FF;
              font-size: 16px;
              color: #0015FF;
            }
            /deep/ .el-button--primary.is-plain:hover {
              background: #0015FF;
              color: #ffffff;
            }
          }
        }
        .partner-2-cards-card-rightline {
          border-right: 1px solid #d4d4d4
        }
      }
    }
    .partner-2-rightbtn {
      width: 135px;
      height: 100%;
      float: left;
      padding-left: 30px;
      padding-top: 16%;
      position: absolute;
      top: 0;
      right: 0;
      img {
        width: 75px;
        height: 46px;
        cursor: pointer;
      }
    }
  }
}
.about-3 {
  background-color: #ffffff;
  position: relative;
  // padding: 8% 10%;
  padding-top: 10%;
  .partner-3-content {
    // width: 1112px;
    width: 100%;
    height: 600px;
    // background-color: rgba(255,0,0,0.1);
    // margin: 0 auto;
    position: relative;
    .partner-3-columns {
      margin-bottom: 55px;
    }
    .partner-3-leftbtn {
      width: 135px;
      height: 100%;
      float: left;
      padding-left: 30px;
      padding-top: 9%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        width: 75px;
        height: 46px;
        cursor: pointer;
      }
    }
    .partner-3-cards {
      width: 1200px;
      height: 100%;
      // float: left;
      // background-color: red;
      // border: 1px solid #d4d4d4;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      /deep/ .el-carousel__container {
        height: 600px;
      }
      /deep/ .el-carousel__arrow--left {
        display: none;
      }
      /deep/ .el-carousel__arrow--right {
        display: none;
      }
      /deep/ .el-carousel__indicators--horizontal {
        display: none;
      }
    }
    .partner-3-rightbtn {
      width: 135px;
      height: 100%;
      float: left;
      padding-left: 30px;
      padding-top: 9%;
      position: absolute;
      top: 0;
      right: 0;
      img {
        width: 75px;
        height: 46px;
        cursor: pointer;
      }
    }
  }
}
.about-title {
  width: 755px;
  height: 200px;
  position: absolute;
  left: 15%;
  top: 40%;
  user-select: none;
  .about-title-text {
    width: 100%;
    // height: 160px;
    // line-height: 180px;
    color: #fff;
    font-size: 80px;
    font-weight: 600;
  }
  .about-title-base {
    width: 140px;
    height: 12px;
    background-color: #4fbf35;
    margin-left: 8px;
  }
}
.about-title-base {
  width: 180px;
  height: 12px;
  background-color: #4fbf35;
  margin-left: 8px;
}
.about-title2 {
  width: 755px;
  height: 350px;
  position: absolute;
  left: 10%;
  top: 13%;
  user-select: none;
  .about-title2-text {
    width: 100%;
    color: #0015FF;
    font-size: 80px;
    font-weight: 600;
  }
  .about-title2-base {
    width: 140px;
    height: 12px;
    background-color: #4fbf35;
    margin-left: 8px;
    margin-bottom: 48px;
    
  }
  .about-title2-descrpt {
    width: 100%;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #0015FF;
    line-height: 27px;
    padding-right: 318px;
    padding-left: 8px;
  }
}
.about-title3 {
  width: 74%;
  height: 200px;
  position: absolute;
  left: 13%;
  top: 2%;
  user-select: none;
  .about-t-title-content {
    height: 100%;
    width: 50%;
    float: left;
    .about-title3-text {
      width: 755px;
      color: #0015FF;
      font-size: 80px;
      font-weight: 600;
      line-height: 80px;
      margin-bottom: 16px;
      padding-top: 10px;
    }
    .about-title3-base {
      width: 140px;
      height: 12px;
      background-color: #4fbf35;
      margin-left: 8px;
      margin-bottom: 48px;
    }
  }
  .about-title3-descrpt {
    width: 50%;
    height: 100%;
    font-size: 30px;
    font-family: SourceHanSansSC;
    font-weight: 500;
    color: #0015FF;
    line-height: 40px;
    float: right;
    padding: 25px 0 0 25%;
  }
}
.about-descript {
  width: 420px;
  height: 200px;
  position: absolute;
  top: 75%;
  right: 10%;
  color: #fff;
  font-size: 16px;
}
.about-left-and-right {
  width: 200px;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgb(49, 49, 255);
  padding: 0 50px;
  .left-arrow {
    width: 40px;
    height: 40px;
    float: left;
    background-image: url("@/assets/Icons/left-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
  .right-arrow {
    width: 40px;
    height: 40px;
    float: right;
    background-image: url("@/assets/Icons/right-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
}
.partner {
  .el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .el-carousel__item:nth-child(2n+1) {
        // background-color: #d3dce6;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators {// 指示器
        left: unset;
        transform: unset;
        right: 2%;
    }
    /deep/ .el-carousel__button {// 指示器按钮
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {// 指示器激活按钮
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators--vertical {
      left: 45px;
      top: 80%;
      width: 20px;
    }
  }
}
</style>
<style  scoped>
.myCarousel >>> .el-carousel .el-carousel__indicators--vertical {
  left: 45px;
  top: 80%;
  width: 20px;
}
.cl-card-btn >>> .el-button--primary.is-plain {
  background: #e0dedf;
}
#partnertitle {
  font-size: 100px;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 100px;
  width: 100%;
  /* height: 110px; */
  width: 800px;
  margin-bottom: 40px;
}
#partner1desc {
  font-size: 24px;
  font-family: SourceHanSansSC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 31px;
  /* width: 243px; */
  width: 355px;
  height: 110px;
  margin-top: 6%;
  padding-left: 10px;
}
.trends-title {
  width: 100%;
  color: #fff;
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 15px;
  -webkit-animation: 8s wobble linear infinite;
  animation: 8s wobble linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
.trends-desc1 {
  -webkit-animation: 2s descFrames1 linear infinite;
  animation: 2s descFrames1 linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
@keyframes wobble {
  0% {
    transform: scale(0, 0);
  }
  1% {
    transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    transform: scale(0.96274018852263, 1.006488198218787);
  }
  18% {
    transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    transform: scale(1.008821093113781, 0.999833501948366);
  }
  25% {
    transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    transform: scale(0.999833501948366, 1.000014970213524);
  }
  37% {
    transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    transform: scale(0.999930953553717, 1.000000476269022);
  }
  47% {
    transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    transform: scale(1.000000687807928, 1.00000000065078);
  }
  71% {
    transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    transform: scale(1.000000456728239, 0.999999999724765);
  }
  74% {
    transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    transform: scale(1.000000000606774, 1.000000000000103);
  }
}
@keyframes descFrames1 {
  0% {
    margin-top: 60%;
    opacity: 0;
  }
  50% {
    margin-top: 10%;
    opacity: .5;
  }
  100% {
    margin-top: 6%;
    opacity: 1;
  }
}
.show-image-name {
transition: all 0.4s;
}
.show-image-name:hover {
      /* box-shadow: 0 8px 8px 0 grey; */
      transform: scale(1.1,1.1);
    }
</style>