<template>
  <div v-loading="isLoading" class="about">
    <el-carousel ref="carouselRef" :initial-index="initialindex" class="myCarousel" :height="screenHeight+'px'" direction="vertical" :loop="false" :autoplay="false" @change="handleChangeCarousel">
      <el-carousel-item name="0">
        <div class="about-card about-1">
          <div class="about-logo" @click="handleReturnHome"></div>
          <div class="about-btns" @click="handleShowMenu"></div>
          <div class="about-next-card" @click="handleNext">
          </div>
          <!-- <div class="about-more-btn" @click="handleShowBottomMenu"></div> -->
          <div v-click-outside="handleOutSide" @mouseleave="handleOutSide" class="about-more-btn-close" :class="{ 'about-more-btn-open': menuOpen }">
            <div class="about-more-btn-col" style="border-bottom: 1px solid #b4b4b4;" @click="handleJumpCards('1')">{{ $t("bilingual0062") }}</div>
            <div class="about-more-btn-col" style="border-bottom: 1px solid #b4b4b4;" @click="handleJumpCards('2')">{{ $t("bilingual0063") }}</div>
            <div class="about-more-btn-col" @click="handleJumpCards('4')">{{ $t("bilingual0064") }}</div>
          </div>
          <div class="about-title">
            <div id="about1title" class="about-title-text">{{ $t("bilingual0001") }}</div>
            <div class="about-title-base"></div>
          </div>
          <div id="home1desc" class="about-descript">
            
            <div @mouseenter="handleShowBottomMenu" style="margin-bottom:20px;">{{ descirpt01 }}</div>
            <!-- <div @mouseenter="handleShowBottomMenu" style="margin-bottom:20px;">{{ $t("bilingual0002") }}</div> -->
            <div @mouseenter="handleShowBottomMenu">{{ descirpt011 }}</div>
            <!-- <div @mouseenter="handleShowBottomMenu">{{ $t("bilingual0003") }}</div> -->
          </div>
          <!-- <div class="about-left-and-right">
            <div class="left-arrow" @click="handlePrevRouter"></div>
            <div class="right-arrow" @click="handleNextRouter"></div>
          </div> -->
        </div>
      </el-carousel-item>
      <el-carousel-item name="1">
        <div class="about-card about-2">
          <div class="about-logo" @click="handleReturnHome"></div>
          <div class="about-btns" @click="handleShowMenu"></div>
          <!-- <div class="about-2-btn-left" @click="moveleft(timeLines)" style="background-color: red;z-index: 100000;"></div>
          <div class="about-2-btn-right" @click="moveright(timeLines)" style="background-color: red;"></div> -->
          <div class="box">
            <div class="img-box">
              <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/about-2-1.jpg" alt="">
            </div>
          </div>
          <div class="time-line">
            <div class="time-line-range">
              <div style="width: 10px;heght:30px">
                <i class="el-icon-back" @click="moveleft(timeLines)"></i>
              </div>            
              <div class="year-start">{{ timeLines[0]?.year }}</div>
              <div class="time-line-title">{{  $t("bilingual0004")}}</div>
              <div class="end-start">{{ timeLines[timeLines.length-1]?.year }}</div>
              <div style="width: 10px;heght:30px">
                <i class="el-icon-right" @click="moveright(timeLines)"></i>
              </div>
              
            </div>
            <div class="about-2-img">
               <div id="my-time-line" class="about-2-img-images">
                 <div v-for="(item, index) in 2" :key="index" class="time-line-point" @mouseenter="handleEnterPoint(item,'init')"></div>
                 <div v-for="(item, index) in timeLines" :key="index+100" class="time-line-point" @mouseenter="handleEnterPoint(item,'other')"></div>
                 <div v-for="(item, index) in 5" :key="index+200" class="time-line-point"></div>
               </div>
            </div>
            <div class="about-2-3">
              <div class="new-year" >
                {{ timeLines[cursorTimeIndex]?.year }}
              </div>
              <div class="descrpt-text" @click="moveright(timeLines)">
                {{ timeLines[cursorTimeIndex]?.desc }}
              </div>
            </div>
          </div>
          <div class="about-next-card" @click="handleNext"></div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="2">
        <div class="about-card about-3">
          <div class="about-logo2" @click="handleReturnHome"></div>
          <div class="about-btns2" style="right: 27px;top: 27px;" @click="handleShowMenu"></div>
          <div class="box">
            <div class="img-box">
              <video muted="muted"
                class="video-box"
                :src="videoSrc"
                type="video/mp4" 
                poster="false.png" 
                
                controls="controls" 
                loop="-1"></video>
            </div>
          </div>
          <div class="about-next-card2" @click="handleNext"></div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="3">
        <div class="about-card about-3">
          <div class="about-logo2" @click="handleReturnHome"></div>
          <div class="about-btns2" style="right: 27px;top: 27px;" @click="handleShowMenu"></div>
          <div class="about-3-left">
            <div class="about-title2">
              <!-- <div class="about-title2-text">{{ $t("bilingual0007") }}</div> -->
              <div class="about-title2-text">{{ title2 }}</div>
              <div class="about-title2-base"></div>
              <div class="about-title2-descrpt">{{ descirpt02 }}</div>
              <!-- <div class="about-title2-descrpt">{{ $t("bilingual0008") }}</div> -->
            </div>
          </div>
          <div class="about-3-right">
            <!-- <img class="about-3-right-img" src="@/assets/BigImage/about-3-1.jpg" alt=""> -->
            <img class="about-3-right-img" :src="images02" alt="">
          </div>
          <div class="about-next-card2" @click="handleNext"></div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="4">
        <div class="about-card about-3">
          <div class="about-logo2" @click="handleReturnHome"></div>
          <div class="about-btns2" style="right: 27px;top: 27px;" @click="handleShowMenu"></div>
          <div class="box">
            <div class="img-box">
              <video muted="muted"
                class="video-box"
                :src="video2"
                type="video/mp4" 
                poster="false.png" 
               
                controls="controls" 
                loop="-1"></video>
            </div>
          </div>
          <div class="about-next-card2" @click="handleNext"></div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="5">
        <div class="about-card about-4">
          <div class="about-logo2" @click="handleReturnHome"></div>
          <div class="about-btns2" @click="handleShowMenu"></div>
          <!-- <div class="about-4-btn-left" @click="handleActionCarousel('left')"></div>
          <div class="about-4-btn-right" @click="handleActionCarousel('right')"></div> -->
          <div class="about-4-images-up" style="height: 718px;width:auto;margin: 0 auto;">
            <!-- <el-carousel ref="about4Carousel" :interval="4000" height="718px" :autoplay="false" arrow="never" indicator-position="outside">
              <el-carousel-item v-for="item in about4Images" :key="item"> -->
                <div class="about-4-images" v-for="item in about4Images" :key="item">
                  <img :src="item" alt="" style="width:100%;height:100%;">
                  <!-- <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/about-4-1.jpg" alt="" style="width:455px;">
                  <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/about-4-2.jpg" alt="" style="width:455px;margin: 0 15px;">
                  <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/about-4-3.jpg" alt="" style="width:397px;">
                  <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/about-4-4.jpg" alt="" style="width:455px;margin-top:15px;">
                  <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/about-4-5.jpg" alt="" style="width:304px;margin: 0 15px;margin-top:15px;">
                  <img src="http://www.vh-marinetech.com/themes/simpleboot3/portal/img/about-4-6.jpg" alt="" style="width:548px;margin-top:15px;"> -->
                </div>
              <!-- </el-carousel-item>
            </el-carousel> -->
          </div>
          <div class="about-next-card" @click="handleNext"></div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="6">
        <div class="about-card about-5">
          <div class="about-logo2" @click="handleReturnHome"></div>
          <div class="about-btns2" @click="handleShowMenu"></div>
          <div class="about-title3">
            <div class="about-t-title-content">
              <div class="about-title3-text" style="font-weight:600;">{{ $t("bilingual0009") }}</div>
              <div class="about-title3-base"></div>
            </div>
            <!-- <div class="about-title3-descrpt">{{ $t("bilingual0010") }}</div> -->
          </div>
          <div class="about-5-seize"></div>
          <div class="about-5-btns">
            <div class="about-5-btn-left" @click="handleAboutMove('left')"></div>
            <div class="about-5-btn-right" @click="handleAboutMove('right')"></div>
          </div>
          <div class="about-t-carousel">
            <div id="carousel-content" class="about-t-carousel-content">
              <div v-for="(item,index) in image4List" :key="index+300" style="height: 100%;float: left;" class="my5">
                 <div class="about-t-name" >
                    <span>{{ item.name }}</span>
                    <!-- <span>{{ item.excerpt }}</span> -->
                  </div>
                  <!-- <img class="about-t-img" src="@/assets/BigImage/about-5-1.png" alt=""> -->
                  <div class="about-t-img-father" >
                    <img class="about-t-img" :src="item.image" alt="" >
                  </div>
                  
              </div>
             

             

            </div>
          </div>
        </div>
      </el-carousel-item>
      <!-- <el-carousel-item name="5" style="background-color: white;">
        <div class="my5content">
          <div class="first">
            <img src="@/assets/Icons/logo-blue.png" alt="">
          </div>
        </div>
      </el-carousel-item> -->
     
    </el-carousel>
    <MyFooter v-if="itemIndex == 6" />
    <Menu ref="menu" />
  </div>
</template>
<script>
import { debounce } from "@/utils"
import MyFooter from "@/components/MyFooter.vue"
import Menu from "@/components/menu.vue"
import '@/utils/directive';
import CountTo from 'vue-count-to'
var timer = null;
var carouselTimer = null;
var that;
export default {
  name: 'about',
  components: {
    MyFooter,Menu,CountTo
  },
  data() {
    return {
      isLoading: false,
      descirpt01:'',
      descirpt011:'',
      title2: '',
      descirpt02: '',
      images02: '',
      image4List: [],
      itemIndex: 0,
      about5ImageWidth: 0,
      menuOpen: false,
      openLock: true,
      lineLeft: 0,
      years: 2016,
      descprit: '',
      about5Left: 0,
      timeLines: [],
      initialindex: 0,
      // timeLines: [{
      //   index: 0,
      //   year: '2016',
      //   desc: ' 2016年——十几个怀揣梦想的橡胶工程师，在国企中走出来，离开舒适的环境，走在了创业的路上。公司设立之初，创始人们定下的发展方向就是海洋工程用特种胶橡胶制品。主要包括橡胶浚管、橡胶输油管等。 '
      // },{
      //   index: 1,
      //   year: '2017',
      //   desc: ' 2017年——十几个怀揣梦想的橡胶工程师，在国企中走出来，离开舒适的环境，走在了创业的路上。公司设立之初，创始人们定下的发展方向就是海洋工程用特种胶橡胶制品。主要包括橡胶浚管、橡胶输油管等。 '
      // },{
      //   index: 2,
      //   year: '2018',
      //   desc: ' 2018年——十几个怀揣梦想的橡胶工程师，在国企中走出来，离开舒适的环境，走在了创业的路上。公司设立之初，创始人们定下的发展方向就是海洋工程用特种胶橡胶制品。主要包括橡胶浚管、橡胶输油管等。 '
      // },{
      //   index: 3,
      //   year: '2019',
      //   desc: ' 2019年——十几个怀揣梦想的橡胶工程师，在国企中走出来，离开舒适的环境，走在了创业的路上。公司设立之初，创始人们定下的发展方向就是海洋工程用特种胶橡胶制品。主要包括橡胶浚管、橡胶输油管等。 '
      // },{
      //   index: 4,
      //   year: '2020',
      //   desc: ' 2020年——十几个怀揣梦想的橡胶工程师，在国企中走出来，离开舒适的环境，走在了创业的路上。公司设立之初，创始人们定下的发展方向就是海洋工程用特种胶橡胶制品。主要包括橡胶浚管、橡胶输油管等。 '
      // },{
      //   index:5,
      //   year: '2021',
      //   desc: ' 2021年——十几个怀揣梦想的橡胶工程师，在国企中走出来，离开舒适的环境，走在了创业的路上。公司设立之初，创始人们定下的发展方向就是海洋工程用特种胶橡胶制品。主要包括橡胶浚管、橡胶输油管等。 '
      // },{
      //   index: 6,
      //   year: '2022',
      //   desc: ' 2022年——十几个怀揣梦想的橡胶工程师，在国企中走出来，离开舒适的环境，走在了创业的路上。公司设立之初，创始人们定下的发展方向就是海洋工程用特种胶橡胶制品。主要包括橡胶浚管、橡胶输油管等。 '
      // },{
      //   index: 7,
      //   year: '2023',
      //   desc: ' 2023年——十几个怀揣梦想的橡胶工程师，在国企中走出来，离开舒适的环境，走在了创业的路上。公司设立之初，创始人们定下的发展方向就是海洋工程用特种胶橡胶制品。主要包括橡胶浚管、橡胶输油管等。 '
      // }],
      cursorTimeIndex: 0,
      about4Images: [],
      video:'',
      videoSrc: '',
      video2:'',
    }
  },
  computed: {
    screenHeight() {
      var clientHeight = 0;
      if(document.body.clientHeight&&document.documentElement.clientHeight){
        clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }else{
        clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      return clientHeight;
    },
    //计算.my5有多少个
    my5Length() {
      return document.querySelectorAll('.my5').length;
    }
  },
  watch: {
    about5ImageWidth(val1,val2) {
      let _fElWidth = document.getElementsByClassName("about-t-carousel")[0].clientWidth;
      let _fElHeight = document.getElementsByClassName("about-t-carousel")[0].clientHeight;
      // let _els = document.getElementsByClassName("about-t-name")
      // for(let i = 0; i < _els.length; i++) {
      //   _els[i].style.lineHeight = _fElHeight + 'px';
      // }
      // let _el1 = document.getElementsByClassName("about-t-name")[0]
      // let _el2 = document.getElementsByClassName("about-t-name")[1]
      // let _el3 = document.getElementsByClassName("about-t-name")[2]
      // _el1.style.width = `${ (_fElWidth - val1*2)/3 }px`;
      // _el2.style.width = `${ (_fElWidth - val1*2)/3 }px`;
      // _el3.style.width = `${ (_fElWidth - val1*2)/3 }px`;
      // _el1.style.lineHeight = _fElHeight + 'px';
      // _el2.style.lineHeight = _fElHeight + 'px';
      // _el3.style.lineHeight = _fElHeight + 'px';


    },
    itemIndex(val1,val2) {
      console.log(181, val1)
      var _el = document.getElementById("my-time-line");
      var _el2 = document.getElementById("carousel-content");
      // if(val1 == '1') {
      //   timer = setInterval(function(){
      //     that.lineLeft = that.lineLeft - 100;
      //     _el.style.left = that.lineLeft + 'px'
      //     if(that.years == 2023) {
      //       that.years = 2016;
      //     }else{
      //       that.years++
      //     }
      //   },3000);
      // }else{
      //   _el.style.left= '0';
      //   that.lineLeft = 0;
      //   that.years = 2016;
      //   clearInterval(timer);
      // }


      // if(val1 == '4') {
      //   carouselTimer = setInterval(function(){
      //     that.about5Left = that.about5Left - 5;
      //     _el2.style.left = that.about5Left + 'px'
      //   },100);
      // }else{
      //   _el2.style.left= '0';
      //   that.about5Left = 0;
      //   clearInterval(carouselTimer);
      // }

      if(val1 == '2') {//视频时重新赋值播放
        this.videoSrc = this.video;
      }else{
        this.videoSrc = '';
      }

      if(val1 == '0') {
        setTimeout(() => {
          document.getElementById("about1title").className="trends-title";
          document.getElementById("home1desc").className="trends-desc";
        },300)
      }else{0
        document.getElementById("about1title").className="about-title-text";
        document.getElementById("home1desc").className="about-descript";
      }

      if(val1 == '3') {
        setTimeout(() => {
          document.getElementById("home3titles").className="trends-title2";
          document.getElementById("home3descs").className="trends-desc2";
        },300)
      }else{
        document.getElementById("home3titles").className="about-3-right-content-col1";
        document.getElementById("home3descs").className="about-3-right-content-col3t";
      }
    }
  },
  mounted() {
    this.initialindex=0
    // getList({
    //   category: '2',
    //   lang: (localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"),
    // }).then(res => {
    //   console.log(366, res)
    // })
    that = this;
    setTimeout(() => {
      document.getElementById("about1title").className="trends-title";
      document.getElementById("home1desc").className="trends-desc";
    },300)
    // debounce
    window.addEventListener('wheel', debounce(this.handleMouseWheel,100,false), {
      passive: false,
    })
    // let img = document.getElementsByClassName("about-t-img")[0];
    // let _this = this;
    // img.onload = () => {
    //   _this.about5ImageWidth = img.clientWidth;
    // };
    this.doInit();
    this.videoInit();
  },
  beforeDestroy() {
    clearInterval(timer);
    clearInterval(carouselTimer);
    window.removeEventListener('wheel', this.handleMouseWheel, {
      passive: false,
    })
  },

  methods: {
    doInit() {
      this.isLoading = true;
      this.axios({
        url: '/api/index/getList',
        method: 'get',
        params: {
          category: '1',
          lang: (localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"),
        }
      }).then(resp => {
        console.log(370, resp)
        let _res = resp.data.data
        this.descirpt01 = _res.description;
        this.descirpt011 = _res.seo_description;
        this.timeLines = _res.child[0]?.list?.map((item,index) => {
          return {
            index: index,
            year: item.excerpt,
            desc: item.content,
          }
        })
        console.log(377, this.timeLines)
        this.years = this.timeLines[0].year;
        this.descprit = this.timeLines[0].desc;
        this.title2 = _res.child[1].list[0].title;
        this.descirpt02 = _res.child[1].list[0].excerpt;
        this.image4List = _res.child[2].list.map(item => {
          return {
            image: item.thumbnail,
            name: item.title,
            excerpt: item.excerpt
          }
        }).filter((item) => {
          return item.image != ''
        })
        this.$nextTick(() => {
          let img = document.getElementsByClassName("about-t-img")[0];
          console.log(404, document.getElementsByClassName("about-t-img")[0].clientWidth)
          this.about5ImageWidth = img.clientWidth;
        });
        
        this.images02 = _res.child[1].list[0].thumbnail
        
        // let _this = this;
        // img.onload = () => {
        //   console.log(406, img)
        //   _this.about5ImageWidth = img.clientWidth;
        // };
        console.log(this.about5ImageWidth)
        this.about4Images = _res.child[1]?.list[0]?.photos;
        console.log(429,this.about4Images)
        this.isLoading = false
      })
    
    },
    moveleft(timeLines) {
      console.log('left')
      let _el = document.getElementById("my-time-line");
      if(this.cursorTimeIndex > 0) {
        _el.style.left = ((this.cursorTimeIndex - 1) * -340) + 'px'
        this.cursorTimeIndex = this.cursorTimeIndex - 1;
      }
    },
    moveright(timeLines) {
      console.log('right')
      let _el = document.getElementById("my-time-line");
      if(this.cursorTimeIndex < timeLines.length - 1) {
        _el.style.left = ((this.cursorTimeIndex + 1) * -340) + 'px'
        this.cursorTimeIndex = this.cursorTimeIndex + 1;
      }
    },
    handleEnterPoint(point,type) {
      let _el = document.getElementById("my-time-line");
      if(type == 'init') {
        this.cursorTimeIndex = 0;
        _el.style.left = '0';
        _el.style.left
        return
      }
      if(this.cursorTimeIndex < point.index) {
        _el.style.left = ((point.index) * -340) + 'px'
      }else{
        _el.style.left = ((point.index) * -340) + 'px'
        // let _more = this.cursorTimeIndex - point.index;
        // _el.style.left = (_more * -340) + 'px'
      }
      this.cursorTimeIndex = point.index;
    },
    handleMouseWheel(e) {
      if (!window.scrollY) {
        if (e.wheelDelta) {
          // 判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) {
            this.handlePrev()
          }
          if (e.wheelDelta < 0) {
            // 当滑轮向下滚动时
            this.handleNext()
          }
        } else if (e.detail) {
          // Firefox滑轮事件
          if (e.detail > 0) {
            // 当滑轮向下滚动时
            this.handleNext();
          }
          if (e.detail < 0) {
            // 当滑轮向上滚动时
            this.handlePrev()
          }
        }
      }
    },
    handleAboutMove(type) {
    //计算整个屏幕的宽度
    console.log(500, this.my5Length)
    var screenWidth = window.screen.width;
console.log("屏幕宽度：" + screenWidth + " 像素");
      clearInterval(carouselTimer);
      var _el = document.getElementById("carousel-content");
      if(type == 'left') {
      console.log(500, this.about5Left)
        if(this.about5Left < 0) {
          this.about5Left = this.about5Left + this.about5ImageWidth;
          _el.style.left = this.about5Left + 'px';
        }
      }else{
      //this.about5Left最大值1200px
        if(this.about5Left > -600*(this.my5Length-3)) {

        console.log(505, this.about5Left)
        this.about5Left = this.about5Left - this.about5ImageWidth;
        _el.style.left = this.about5Left + 'px';
        }else{
          // this.about5Left = 0;
          _el.style.left = this.about5Left + 'px';
        }
      }
    },
    handleNext() {
      this.$refs.carouselRef?.next();
    },
    handlePrev() {
      this.$refs.carouselRef?.prev();
    },
    handleChangeCarousel(newValue,oldValue) {
      this.itemIndex = newValue;
    },
    handlePrevRouter() {
      this.$store.dispatch('myTools/switchRouter', 'about').then(res => {
        if(res.prev) {
          this.$router.push({
            path: res.prev
          })
        }
      })
    },
    handleNextRouter() {
      this.$store.dispatch('myTools/switchRouter', 'about').then(res => {
        if(res.next) {
          this.$router.push({
            path: res.next
          })
        }
      })
    },
    handleMouseEnter() {
      console.log(250)
    },
    handleShowMenu() {
      console.log(243)
      this.$refs.menu.handleShow();
    },
    handleOutSide() {
      if(!this.openLock){
        this.menuOpen = false;
        this.openLock = true;
      }
    },
    handleShowBottomMenu() {
      // this.menuOpen = true;
      // let _this = this
      // setTimeout(() => {
      //   _this.openLock = false;
      // },200)
    },
    handleJumpCards(index) {
      this.$refs.carouselRef.setActiveItem(index);
      this.handleOutSide();
    },
    handleActionCarousel(action) {
      if(action == 'left') {
        this.$refs.about4Carousel.prev();
      }else{
        this.$refs.about4Carousel.next();
      }
    },
    handleReturnHome() {
      this.$router.push({ path: '/' });
    },
    videoInit() {
      this.isLoading = true;
      this.axios({
        url: '/api/index/getList',
        method: 'get',
        params: {
          category: '35',
          lang:  (localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"),
        }
      }).then(resp => {
        if(resp.data.data.length > 0){
        console.log(600, resp.data.data[0].video)
         this.video = resp.data.data[0].video;
         this.video2 = resp.data.data[1].video;
        }else{
          this.video = resp.data.data.video;
        }
        this.videoSrc = this.video
        this.isLoading = false;
      })
    },
  }
}
</script>
<style lang="scss" scoped>
// @import "@style/c.css";
// @import "@style/s.css";
// @import "@style/v.css";
.about {
  width: 100%;
}
.about-card {
  width: 100%;
  height: 100vh;
  position: relative;
}
.about-logo {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-write.png");
  background-size: 100% 100%;
  cursor: pointer;
  z-index: 5;
}
.about-logo2 {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-blue.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.about-btns {
  width: 60px;
  // height: 60px;
  height: 20px;
  // background-image: url("@/assets/Icons/more.png");
  background-image: url("@/assets/Icons/more3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
  z-index: 5;
}
.about-btns2 {
  width: 60px;
  // height: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more-blue3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
}
.about-next-card {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn.png");
  background-size: 100% 100%;
  opacity: .4;
  z-index: 9;
  
}
.about-next-card:hover {
  cursor: pointer;
  opacity: .9;
}
.about-more-btn {
  position: absolute;
  right: 60px;
  bottom: 40px;
  width: 60px;
  height: 60px;
  background-image: url("@/assets/Icons/about-menu.png");
  background-size: 100% 100%;
  // display: block;
  cursor: pointer;
  -moz-transition: all .5s ease-out, opacity 0.5s ease-out;
  -o-transition: all .5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all .5s ease-out, opacity 0.5s ease-out;
  transition: all .5s ease-out, opacity 0.5s ease-out;
}
.about-more-btn-close {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 0px;
  background-color: #0515ef;
  z-index: 1;
  -moz-transition: all .5s ease-out, opacity 0.5s ease-out;
  -o-transition: all .5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all .5s ease-out, opacity 0.5s ease-out;
  transition: all .5s ease-out, opacity 0.5s ease-out;
  border-radius: 100px;
}
.about-more-btn-open {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 40%;
  height: 540px;
  background-color: #0515ef;
  z-index: 5;
  border-radius: 1px;
}
.about-more-btn-col {
  height: 180px;
  width: 100%;
  background-color: #0515ef;
  -moz-transition: all .5s ease-out, opacity 0.5s ease-out;
  -o-transition: all .5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all .5s ease-out, opacity 0.5s ease-out;
  transition: all .5s ease-out, opacity 0.5s ease-out;
  // border-radius: 100px;
  font-size: 36px;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 180px;
  padding-left: 68px;
  box-sizing: border-box;
  cursor: pointer;
}
.about-more-btn-col-hide {
  height: 0px;
  width: 0px;
  background-color: #0515ef;
  border-radius: 100px;
  -moz-transition: all .5s ease-out, opacity 0.5s ease-out;
  -o-transition: all .5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all .5s ease-out, opacity 0.5s ease-out;
  transition: all .5s ease-out, opacity 0.5s ease-out;
  border-radius: 100px;
}
.about-next-card2 {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn2.png");
  background-size: 100% 100%;
  opacity: .4;
  z-index: 9;
}
.about-next-card2:hover {
  cursor: pointer;
  opacity: .9;
}
.about-1 {
  background-image: url("@/assets/BigImage/about-1-1.jpg");
  background-size: 100% 100%;
}
.about-2 {
  background-color: #001f3e;
  position: relative;
  .box {
    height: 100%;
    margin: 0 auto;
    .img-box {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
    .img-box img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      margin: auto;
      z-index: -1;
      zoom: 1;
    };
    .img-box:before {
      content: '';
      display: inline-block;
      padding-bottom: 100%;
      width: 0.1px;
      vertical-align: middle;
    }
  }
  .time-line {
    position: absolute;
    width: 100%;
    height: 60%;
    top: 30%;
    left: 0;
    // background-color: red;
    z-index: 2;
    .time-line-range {
      height: 67px;
      width: 100%;
      line-height: 67px;
      font-size: 24px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 29px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      .year-start {
        width: 30%;
        float: left;
        padding-left: 70px;
        text-align: left;
      }
      .time-line-title {
        width: 40%;
        float: left;
        text-align: center;
        font-size: 48px;
        font-family: SourceHanSansSC;
        font-weight: bold;
        color: #51C934;
      }
      .end-start {
        width: 30%;
        float: right;
        padding-right: 70px;
        text-align: right;
      }
    }
    .about-2-img {
      width: calc(100% - 44px);
      margin:0 22px;
      height: 290px;
      margin-bottom: 3%;
      position: relative;
      overflow: hidden;
    }
    .about-2-3 {
      width: 100%;
      height: 35%;
      .new-year {
        width: 20%;
        height: 120px;
        float: left;
        // margin-top: 3%;
        margin-left: 10%;
        font-size: 140px;
        font-family: SourceHanSansSC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 120px;
        text-align: right;
      }
      .descrpt-text {
        width: 27%;
        // height: 220px;
        float: right;
        // background-color: burlywood;
        // margin-top: 2%;
        margin-right: 4%;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        // overflow: auto;
      }
    }
  }
}
.about-3 {
  position: relative;
  background-color: #ffffff;
  .about-3-left {
    width: 40%;
    height: calc(100% - 110px);
    float: left;
    // background-color: rgba(34, 57%, 70%,.1);
  }
  .about-3-right {
    width: 60%;
    height: calc(100% - 110px);
    float: left;
    // background-color:rgba(182, 25%, 50%,.1);
    text-align: right;
    padding-top: 4%;
    padding-right: 30px;
    .about-3-right-img {
      height: 100%;
      width: 100%;
      transition: all 0.4s;//设置上浮过渡时间
    }
    .about-3-right-img:hover {
      height: 100%;
      width: 100%;
      box-shadow: 0 8px 8px 0 grey;
      transform: scale(1.1,1.1);
    }
  }
}
.about-4 {
  background-color: #e0dedf;
  padding: 4% 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  .about-4-images-up{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-4-images {
    width: 100%;
    height: 100%;
    // background-color: red;
    // background-color: #ffffff;
    // display: flex;
    // align-items: center;
    img {
      float: left;
      height: 351px;
    }
  }
  .about-4-btn-left {
    width: 75px;
    height: 46px;
    background-image: url("@/assets/Icons/about5-left.png");
    background-size: 100% 100%;
    cursor: pointer;
    position: absolute;
    left: 65px;
    top: 50%;
  }
  .about-4-btn-right {
    width: 75px;
    height: 46px;
    background-image: url("@/assets/Icons/about5-right.png");
    background-size: 100% 100%;
    cursor: pointer;
    position: absolute;
    right: 65px;
    top: 50%;
  }
}
.about-5 {
  background-color: #ffffff;
  padding-top: 30px;
  .about-5-top {
    height: 380px;
    width: 100%;
    // background-color: red;
    padding: 40px 50px 0 300px;
  }
  .about-5-seize {
    width: 100%;
    height: 300px;
  }
  .about-5-btns {
    height: 80px;
    width: 100%;
    padding: 0 60px;
    .about-5-btn-left {
      width: 92px;
      height: 56px;
      background-image: url("@/assets/Icons/about5-left.png");
      background-size: 100% 100%;
      float: left;
      cursor: pointer;
    }
    .about-5-btn-right {
      width: 92px;
      height: 56px;
      background-image: url("@/assets/Icons/about5-right.png");
      background-size: 100% 100%;
      float: right;
      cursor: pointer;
    }
  }
  .about-t-carousel {
    width: 100%;
    height:auto;
    /*background-color: #0015FF;*/
    position: relative;
    .about-t-carousel-content {
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      transition: 1s;
      width: auto;
      margin-right: 60px;
      display: flex;
      justify-content: flex-start;
      background-color: #0015FF;
    }
    .about-t-img-father{
      display: flex;
      justify-content: center;
      width: 50%;
     
      .about-t-img {
        height: 100%;
        width: 100%;
        float: left;
        //margin-right: 100px;
      }
    }
    
    .about-t-name {
      height: 100%;
      width: 50%;
      display: flex;
      justify-content: center;
     
      // line-height: 650px;
      padding-top: 33%;
      text-align: center;
      font-size: 19px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #FFFFFF;
      //float: left;
    }
  }
}
.about-title {
  width: 795px;
  height: 200px;
  position: absolute;
  left: 7%;
  top: 40%;
  user-select: none;
  .about-title-text {
    width: 100%;
    // height: 160px;
    // line-height: 180px;
    color: #fff;
    font-size: 80px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .about-title-base {
    width: 147px;
    height: 12px;
    background-color: #4fbf35;
    margin-left: 8px;
  }
}
.about-title2 {
  width: 655px;
  height: 350px;
  position: absolute;
  left: 16%;
  top: 14%;
  user-select: none;
  .about-title2-text {
    width: 100%;
    color: #0015FF;
    font-size: 80px;
    font-weight: 600;
  }
  .about-title2-base {
    width: 180px;
    height: 12px;
    background-color: #4fbf35;
    margin: 23px 0 48px 8px;
  }
  .about-title2-descrpt {
    width: 100%;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #0015FF;
    line-height: 27px;
    padding-right: 240px;
    padding-left: 8px;
  }
}
.about-title3 {
  width: 74%;
  height: 200px;
  position: absolute;
  left: 15%;
  top: 2%;
  user-select: none;
  .about-t-title-content {
    height: 100%;
    width: 50%;
    float: left;
    .about-title3-text {
      width: 755px;
      color: #0015FF;
      font-size: 80px;
      // font-weight: 600;
      line-height: 80px;
      margin-bottom: 16px;
      padding-top: 30px;
    }
    .about-title3-base {
      width: 170px;
      height: 12px;
      background-color: #4fbf35;
      margin-left: 8px;
      margin-bottom: 48px;
    }
  }
  .about-title3-descrpt {
    width: 50%;
    height: 100%;
    font-size: 30px;
    font-family: SourceHanSansSC;
    font-weight: 500;
    color: #0015FF;
    line-height: 40px;
    float: right;
    padding: 25px 0 0 23%;
  }
}
.about-descript {
  width: 420px;
  height: 200px;
  position: absolute;
  top: 75%;
  right: 10%;
  color: #fff;
  font-size: 16px;
}
.about-left-and-right {
  width: 200px;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgb(49, 49, 255);
  padding: 0 50px;
  .left-arrow {
    width: 40px;
    height: 40px;
    float: left;
    background-image: url("@/assets/Icons/left-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
  .right-arrow {
    width: 40px;
    height: 40px;
    float: right;
    background-image: url("@/assets/Icons/right-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
}
.about {
  .el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
        // background-color: #99a9bf;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .el-carousel__item:nth-child(2n+1) {
        // background-color: #d3dce6;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators {// 指示器
        left: unset;
        transform: unset;
        right: 2%;
    }
    /deep/ .el-carousel__button {// 指示器按钮
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {// 指示器激活按钮
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators--vertical {
      left: 45px;
      top: 80%;
      width: 20px;
    }
  }
}
.about-4 {
  .el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
        // background-color: #99a9bf;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .el-carousel__item:nth-child(2n+1) {
        // background-color: #d3dce6;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators {// 指示器
        left: unset;
        transform: unset;
        right: 2%;
        display: none;
    }
    /deep/ .el-carousel__button {// 指示器按钮
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {// 指示器激活按钮
        background: red;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators--vertical {
      left: 45px;
      top: 80%;
      width: 20px;
    }
  }
}
.my-time-line {
  // position: absolute;
  // top: 0;
  // left: 0;
  // transition: 1s;
  // background-image: url("@/assets/BigImage/time-line.png");
  // background-size: 100% 100%;
}
.about-2-img-images {
  position: absolute;
  top: 0;
  left: 0;
  transition: 1s;
  height: 100%;
  width: 200000px;
  background-size: 1% 100%;
  overflow: hidden;
}
.time-line-point{
  width: 340px;
  height: 290px;
  float: left;
  background-image: url("@/assets/BigImage/time-line2.png");
  background-size: 100% 100%;
}
</style>
<style  scoped>
.myCarousel >>> .el-carousel .el-carousel__indicators--vertical {
  left: 45px;
  top: 80%;
  width: 20px;
}
.trends-title {
  width: 100%;
  color: #fff;
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 15px;
  -webkit-animation: 8s wobble linear infinite;
  animation: 8s wobble linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.trends-desc {
  -webkit-animation: 2s descFrames linear infinite;
  animation: 2s descFrames linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
#home1desc {
  width: 420px;
  height: 200px;
  position: absolute;
  top: 67%;
  right: 10%;
  color: #fff;
  font-size: 16px;
}
@keyframes wobble {
  0% {
    transform: scale(0, 0);
  }
  1% {
    transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    transform: scale(0.96274018852263, 1.006488198218787);
  }
  18% {
    transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    transform: scale(1.008821093113781, 0.999833501948366);
  }
  25% {
    transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    transform: scale(0.999833501948366, 1.000014970213524);
  }
  37% {
    transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    transform: scale(0.999930953553717, 1.000000476269022);
  }
  47% {
    transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    transform: scale(1.000000687807928, 1.00000000065078);
  }
  71% {
    transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    transform: scale(1.000000456728239, 0.999999999724765);
  }
  74% {
    transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    transform: scale(1.000000000606774, 1.000000000000103);
  }
}
@keyframes descFrames {
  0% {
    top: 100%;
    opacity: 0;
  }
  50% {
    top: 60%;
    opacity: .7;
  }
  100% {
    top: 65%;
    opacity: 1;
  }
}
.show-image-name {
  transition: all 0.4s;
}
.show-image-name:hover {
  box-shadow: 0 8px 8px 0 grey;
  transform: scale(1.1,1.1);
}
.video-box{
  width: 80%;
  height: 80vh;
  margin: 12vh 10% 8vh;
}
.my5{
  display: flex;
  justify-content: flex-start;
  width: 600px;
  
 
 
}
.my5content{
  background-color: white;
}
</style>