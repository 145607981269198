<template>
  <div class="news">
    <el-carousel ref="carouselRef" :initial-index="initialIndex" class="myCarousel" :height="screenHeight+'px'" direction="vertical" :loop="false" :autoplay="false" @change="handleChangeCarousel">
      <el-carousel-item name="0">
        <div class="about-card about-1">
          <div class="about-logo" @click="handleReturnHome"></div>
          <div class="about-btns" @click="handleShowMenu"></div>
          <div class="about-next-card" @click="handleNext">
          </div>
          <div v-click-outside="handleOutSide" @mouseleave="handleOutSide" class="about-more-btn-close" :class="{ 'about-more-btn-open': menuOpen }">
            <div class="about-more-btn-col" style="border-bottom: 1px solid #b4b4b4;" @click="handleJumpCards('1')">{{ $t("bilingual0065") }}</div>
            <div class="about-more-btn-col" @click="handleJumpCards('5')">{{ $t("bilingual0058") }}</div>
          </div>
          <div class="click-show-menu" @mouseenter="handleShowBottomMenu"></div>
          <div id="news-title-content" class="about-title">
            <!-- <div id="news1title" class="about-title-text">{{ $t("bilingual0050") }}</div> -->
            <div id="news1title" class="about-title-text">{{ news01.name }}</div>
            <div class="about-title-base"></div>
          </div>
          <div id="newsdesc" class="about-descript">
            <div>{{ news01.description }}</div>
            <!-- <div>{{ $t("bilingual0051") }}</div> -->
            <!-- <div>{{ $t("bilingual0052") }}</div> -->
          </div>
        </div>
      </el-carousel-item>
      <!-- participatingArrComputed participatingOpen -->
      <template v-for="(item,index) in allArrs">
        <el-carousel-item v-if="item.type == 'participating'" :key="index" :name="(index + 1).toString()">
          <div class="about-card about-2">
            <div class="about-logo2" @click="handleReturnHome"></div>
            <div class="about-btns2" @click="handleShowMenu"></div>
            <div class="about-next-card2" @click="handleNext"></div>
            <div class="news-title">
              <div :id="'newstitle2'" class="news-title-text">{{ item.title }}
                <div class="newstitle2-2">{{ item.title2 }}</div>
              </div>
              <div class="news-title-line"></div>
            </div>
            <div class="news-content">
              <div :id="'newsdesc2'" class="news-content-desc">
                {{ item.desc }}
              </div>
              <div class="news-content-carousel" style="position: relative;">
                <div class="carousel-main">
                  <img class="show-image-name" :src="item.photos.find(val => val.main == true)?.img" alt="">
                </div>
                <div class="carousel-list">
                  <img v-for="(items) in item.photos.filter(val => val.main != true)" :key="items.id" class="show-image-name" :src="items.img" alt="" @click="handleSetMain(index,items.id)">
                </div>
                <div v-if="(!participatingOpen) && participatingArr.length > 1" style="
                    position: absolute; 
                    right: 200px;
                    bottom: 80px;
                    height: 35px;
                    width: 120px;
                    text-align:center;
                    line-height: 35px;
                    background-color:#0015FF;
                    color: #fff;
                    cursor: pointer;"
                  @click="handleActionArr('participatingOpen')"
                >{{ $t("bilingual0086") }}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item v-else :key="index" :name="(index + 1).toString()">
          <div class="about-card about-6">
            <div class="about-logo2" @click="handleReturnHome"></div>
            <div class="about-btns2" @click="handleShowMenu"></div>
            <div class="news-title">
              <div id="newstitle6" class="news-title-text">{{ item.title }}</div>
              <div class="news-title-line"></div>
            </div>
            <div class="news-content">
              <div class="news-content-carousel">
                <div class="carousel-main">
                  <img class="show-image-name" :src="itemsImg ? itemsImg : item.photos.find(val => val.main == true)?.img" alt="">
                </div>
                <div class="carousel-list">
                  <img class="show-image-name" v-for="(items) in item.photos.filter(val => val.main != true)" :key="items.id" :src="items.img" @mouseover="mouseover(items.img)" alt="">
                </div>
                <div v-if="(!oursOpen) && oursArr.length > 1" style="
                  position: absolute; 
                  right: 200px;
                  bottom: 80px;
                  height: 35px;
                  width: 120px;
                  text-align:center;
                  line-height: 35px;
                  background-color:#0015FF;
                  color: #fff;
                  cursor: pointer;"
                  @click="handleActionArr('oursOpen')"
                  >{{ $t("bilingual0086") }}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </template>
      <!-- <template v-for="(items,index) in oursArr">
        <el-carousel-item v-show="index == 0 || oursOpen" :key="index" :name="(participatingArrComputed.length + 1).toString()">
          <div class="about-card about-6">
            <div class="about-logo2" @click="handleReturnHome"></div>
            <div class="about-btns2" @click="handleShowMenu"></div>
            <div class="news-title">
              <div id="newstitle6" class="news-title-text">{{ items.title }}</div>
              <div class="news-title-line"></div>
            </div>
            <div class="news-content">
              <div class="news-content-carousel">
                <div class="carousel-main">
                  <img class="show-image-name" :src="items.photos.find(val => val.main == true)?.img" alt="">
                </div>
                <div class="carousel-list">
                  <img class="show-image-name" v-for="(item) in items.photos.filter(val => val.main != true)" :key="item.id" :src="item.img" alt="">
                </div>
                <div v-if="!oursOpen" style="
                  position: absolute; 
                  right: 200px;
                  bottom: 80px;
                  height: 35px;
                  width: 120px;
                  text-align:center;
                  line-height: 35px;
                  background-color:#0015FF;
                  color: #fff;
                  cursor: pointer;"
                  @click="handleActionArr('oursOpen')"
                  >更多内容</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </template> -->
    </el-carousel>
    <MyFooter v-if="itemIndex == allArrs.length" />
    <Menu ref="menu" />
  </div>
</template>
<script>
import { debounce } from "@/utils"
import MyFooter from "@/components/MyFooter.vue";
import Menu from "@/components/menu.vue"
import '@/utils/directive';
export default {
  name: 'news',
  components: {
    MyFooter,Menu
  },
  data() {
    return {
      news01: {},
      news02: {},
      news03: {},
      news04: {},
      news05: {},
      news06: {},
      isLoading: false,
      itemIndex: 0,
      about5ImageWidth: 0,
      photo1: [],
      photo2: [],
      photo3: [],
      photo4: [],
      photo5: [],
      menuOpen: false,
      participatingArr: [],
      oursArr: [],
      participatingOpen: false,
      oursOpen: false,
      participatingArrComputed: [],
      oursArrComputed: [],
      initialIndex: 0,
      itemsImg:''
    }
  },
  watch: {
    itemIndex(val1,val2) {
      console.log(135, val1)
      if(val1 == '0') {
        setTimeout(() => {
          document.getElementById("news1title").className="trends-title";
          document.getElementById("newsdesc").className="trends-desc";
        },300)
      }else{
        document.getElementById("news1title").className="about-title-text";
        document.getElementById("newsdesc").className="about-descript";
      }

      if(val1 == '1') {
        document.getElementById("newstitle2").className="trends-title2";
        document.getElementById("newsdesc2").className="trends-desc2";
      }else{
        document.getElementById("newstitle2").className="about-title-text";
        document.getElementById("newsdesc2").className="about-title-text";
      }

      // if(val1 == '2') {
      //   document.getElementById("newstitle3").className="trends-title2";
      //   document.getElementById("newsdesc3").className="trends-desc2";
      // }else{
      //   document.getElementById("newstitle3").className="news-title-text";
      //   document.getElementById("newsdesc3").className="news-content-desc";
      // }

      // if(val1 == '3') {
      //   document.getElementById("newstitle4").className="trends-title2";
      //   document.getElementById("newsdesc4").className="trends-desc2";
      // }else{
      //   document.getElementById("newstitle4").className="news-title-text";
      //   document.getElementById("newsdesc4").className="news-content-desc";
      // }

      // if(val1 == '4') {
      //   document.getElementById("newstitle5").className="trends-title2";
      //   document.getElementById("newsdesc5").className="trends-desc2";
      // }else{
      //   document.getElementById("newstitle5").className="news-title-text";
      //   document.getElementById("newsdesc5").className="news-content-desc";
      // }

      if(val1 == '5') {
        setTimeout(() => {
          document.getElementById("newstitle6").className="trends-title2";
        },300)
      }else{
        document.getElementById("newstitle6").className="news-title-text";
      }

    }
  },
  computed: {
    computedPh1ArrMain() {
      return this.photo1.find(item => item.main == true)
    },
    computedPh1ArrOther() {
      return this.photo1.filter(item => item.main == false)
    },
    computedPh2ArrMain() {
      return this.photo2.find(item => item.main == true)
    },
    computedPh2ArrOther() {
      return this.photo2.filter(item => item.main == false)
    },
    computedPh3ArrMain() {
      return this.photo3.find(item => item.main == true)
    },
    computedPh3ArrOther() {
      return this.photo3.filter(item => item.main == false)
    },
    computedPh4ArrMain() {
      return this.photo4.find(item => item.main == true)
    },
    computedPh4ArrOther() {
      return this.photo4.filter(item => item.main == false)
    },
    computedPh5ArrMain() {
      return this.photo5.find(item => item.main == true)
    },
    computedPh5ArrOther() {
      return this.photo5.filter(item => item.main == false)
    },
    screenHeight() {
      var clientHeight = 0;
      if(document.body.clientHeight&&document.documentElement.clientHeight){
        clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }else{
        clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      return clientHeight;
    },
    // participatingArrComputed() {
    //   return this.participatingOpen ? this.participatingArr : [ this.participatingArr[0] ]
    // },
    // oursArrComputed() {
    //   return this.oursOpen ? this.oursArr : [ this.oursArr[0] ]
    // },
    allArrs() {
      let _arr1 = this.participatingOpen ? this.participatingArr : [ this.participatingArr[0] ];
      let _arr2 = this.oursOpen ? this.oursArr : [ this.oursArr[0] ];
      return [..._arr1, ..._arr2]
    },
    footerLength() {
      return (this.participatingOpen ? this.participatingArr.length : 1 ) + (this.oursOpen ? this.oursArr.length : 1 )
    },
  },
  created() {
    this.doInit()
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("news1title").className="trends-title";
      document.getElementById("newsdesc").className="trends-desc";
    },300)
    // debounce
    window.addEventListener('wheel', debounce(this.handleMouseWheel,100,false), {
      passive: false,
    })
    this.$nextTick(() => {
      let _el = document.getElementById("news-title-content");
      console.log(384, _el)
      _el.style.top = '23%';
    })
    console.log(382,(localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"))
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.handleMouseWheel, {
      passive: false,
    })
  },
  methods: {
    handleActionArr(arg) {
      this[arg] = true;
      if(this.participatingOpen) {
        console.log(315)
        this.initialIndex = 2
        this.$refs.carouselRef.setActiveItem(2);
      }
      if(this.oursOpen) {
        this.initialIndex = this.participatingArr.length + 1
        this.$refs.carouselRef.setActiveItem(this.participatingArr.length + 1);
      }
    },
    doInit() {
      console.log('dddd')
      this.isLoading = true;
      this.axios({
        url: '/api/index//getList',
        method: 'get',
        params: {
          category: '4',
          lang:  (localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"),
        }
      }).then(resp => {
        console.log(333, resp)
        let res = resp.data.data;
        console.log(335, res)
        /*  -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --  */
        this.participatingArr = res.child[0]?.list.map((item, index) => {
          let photoaa = []
          if(item.photos) {
            photoaa = item.photos.map((val, i) => {
              return {
                id: index+'*'+i,
                main: i == 0 ? true: false,
                img: val
              }
            })
          }else{
            photoaa = []}
          return {
            title: item.title,
            title2: item.excerpt,
            desc: item.content,
            type: 'participating',
            photos:photoaa
            // photos: item.photos.map((val, i) => {
            //   return {
            //     id: index+'*'+i,
            //     main: i == 0 ? true: false,
            //     img: val
            //   }
            // })
          }
        })
        console.log('5555')
        this.oursArr = res.child[1]?.list.map((item, index) => {
          return {
            title: item.title,
            type: 'ours',
            photos: item.photos.map((val, i) => {
              return {
                id: index+'*'+i,
                main: i == 0 ? true: false,
                img: val
              }
            })
          }
        })
        console.log(3321, this.participatingArr)
        console.log(3322, this.oursArr)
        /*  -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --  */
        console.log(373, res)
        this.news01 = {
          description: res.description,
          name: res.name,
        }

        if(res.child[0].list[1].photos) {
          this.photo1 = res.child[0].list[1].photos?.map((item, index) => {
            return {
              id: index + 1,
              main: false,
              img: item,
            }
          })
          this.photo1[0].main = true;
        }
        this.news02 = {
          title: res.child[0]?.list[1]?.title,
          title2: res.child[0]?.list[1]?.excerpt,
          desc: res.child[0]?.list[1]?.content,
          image: res.child[0]?.list[1]?.photos,
        }


        if(res.child[0].list[0].photos) {
          this.photo2 = res.child[0].list[0].photos?.map((item, index) => {
            return {
              id: index + 1,
              main: false,
              img: item,
            }
          })
          this.photo2[0].main = true;
        }
        this.news03 = {
          title: res.child[0]?.list[0]?.title,
          title2: res.child[0]?.list[0]?.excerpt,
          desc: res.child[0]?.list[0]?.content,
          image: res.child[0]?.list[0]?.photos,
        }


        if(res.child[0].list[2].photos) {
          this.photo3 = res.child[0].list[2].photos?.map((item, index) => {
            return {
              id: index + 1,
              main: false,
              img: item,
            }
          })
          this.photo3[0].main = true;
        }
        this.news04 = {
          title: res.child[0]?.list[2]?.title,
          title2: res.child[0]?.list[2]?.excerpt,
          desc: res.child[0]?.list[2]?.content,
          image: res.child[0]?.list[2]?.photos,
        }

        // this.news05
        this.news05 = {
          title: res.child[0]?.list[3]?.title,
          title2: res.child[0]?.list[3]?.excerpt,
          desc: res.child[0]?.list[3]?.content,
          image: res.child[0]?.list[3]?.photos,
        }
        if(res.child[0].list[3].photos) {
          this.photo4 = res.child[0].list[3].photos?.map((item, index) => {
            return {
              id: index + 1,
              main: false,
              img: item,
            }
          })
          this.photo4[0].main = true;
        }

        // this.news05
        this.news06 = {
          title: res.child[1]?.name,
          // desc: res.child[1]?.list[0]?.excerpt,
          // image: res.child[1]?.list[0]?.photos,
        }
        if(res.child[1].list) {
          this.photo5 = res.child[1].list[0]?.photos.map((item, index) => {
            return {
              id: index + 1,
              main: false,
              img: item,
            }
          })
          this.photo5[0].main = true;
        }
        console.log(358, res)
        // this.participatingArrComputed = [ this.participatingArr[0] ];
        // this.oursArrComputed = [ this.oursArr[0] ];
        // this.handleActionArr();
        this.isLoading = false;
      })
    },
    handleMouseWheel(e) {
      if (!window.scrollY) {
        if (e.wheelDelta) {
          // 判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) {
            this.handlePrev()
          }
          if (e.wheelDelta < 0) {
            // 当滑轮向下滚动时
            this.handleNext()
          }
        } else if (e.detail) {
          // Firefox滑轮事件
          if (e.detail > 0) {
            // 当滑轮向下滚动时
            this.handleNext();
          }
          if (e.detail < 0) {
            // 当滑轮向上滚动时
            this.handlePrev()
          }
        }
      }
    },
    handleNext() {
      this.$refs.carouselRef?.next();
    },
    handlePrev() {
      this.$refs.carouselRef?.prev();
    },
    handleChangeCarousel(newValue,oldValue) {
      this.itemIndex = newValue;
    },
    handlePrevRouter() {
      this.$store.dispatch('myTools/switchRouter', 'news').then(res => {
        if(res.prev) {
          this.$router.push({
            path: res.prev
          })
        }
      })
    },
    handleNextRouter() {
      this.$store.dispatch('myTools/switchRouter', 'news').then(res => {
        if(res.next) {
          this.$router.push({
            path: res.next
          })
        }
      })
    },
    handleSetMain(index,id) {
      console.log(421, index,id)
      this.participatingArr[index].photos.forEach(item => {
        if(item.id == id) {
          item.main = true
        }else{
          item.main = false
        }
      })
      console.log(425, this.participatingArr[index].photos)
    },
    handleShowMenu() {
      this.$refs.menu.handleShow();
    },
     handleOutSide() {
      console.log(427)
      if(!this.openLock){
        this.menuOpen = false;
        this.openLock = true;
      }
    },
    handleShowBottomMenu() {
      this.menuOpen = true;
      let _this = this
      setTimeout(() => {
        _this.openLock = false;
      },200)
    },
    handleJumpCards(index) {
      this.$refs.carouselRef.setActiveItem(index);
      this.handleOutSide();
    },
    handleActionCarousel(action) {
      if(action == 'left') {
        this.$refs.about4Carousel.prev();
      }else{
        this.$refs.about4Carousel.next();
      }
    },
    handleReturnHome() {
      this.$router.push({ path: '/' });
    },
    mouseover(img){
      this.itemsImg = img;
    }
  }
}
</script>
<style lang="scss" scoped>
.news {
  width: 100%;
}
.about-card {
  width: 100%;
  height: 100vh;
  position: relative;
}
.about-logo {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-write.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.about-logo2 {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-blue.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.about-btns {
  width: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
}
.about-btns2 {
  width: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more-blue3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
}
.about-next-card {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn.png");
  background-size: 100% 100%;
  opacity: .4;
  z-index: 9;
  
}
.about-next-card:hover {
  cursor: pointer;
  opacity: .9;
}
.about-next-card2 {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn2.png");
  background-size: 100% 100%;
  opacity: .4;
  z-index: 9;
}
.about-next-card2:hover {
  cursor: pointer;
  opacity: .9;
}
.about-1 {
  background-image: url("@/assets/BigImage/news1-1.jpg");
  background-size: 100% 100%;
}
.about-2 {
  background-color: #ffffff;
  position: relative;
  padding: 9% 0 0 17%;
  .news-title {
    width: 100%;
    height: 200px;
    .news-title-text {
      font-size: 85px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #0015FF;
      margin-bottom: 15px;
      position: relative;
    }
    .news-title-line {
      width: 200px;
      height: 12px;
      background-color: #4fbf35;
    }
  }
  .news-content {
    widows: 100%;
    height: calc(100% - 200px);
    .news-content-desc {
      height: 100%;
      width: 300px;
      float: left;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #AAAAAB;
      line-height: 27px;
      padding-top: 30px;
      padding-right: 55px;
    }
    .news-content-carousel {
      height: 100%;
      width: calc(100% - 300px);
      float: left;
      box-sizing: border-box;
      border-top: 1px solid #d4d4d4;
      padding-top: 30px;
      .carousel-main {
        width: 520px;
        height: 390px;
        float: left;
        margin-right: 28px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .carousel-list {
        width: calc(100% - 548px);
        height: 100%;
        float: left;
        padding-right: 11%;
        img {
          height: 180px;
          width: 240px;
          float: left;
          margin-right: 20px;
          cursor: pointer;
          margin-bottom: 28px;
        }
      }
    }
  }
}
// 0514f5
.about-3 {
  position: relative;
  background-color: #ffffff;
  padding: 9% 0 0 17%;
  .news-title {
    width: 100%;
    height: 200px;
    .news-title-text {
      font-size: 85px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #0514f5;
      margin-bottom: 15px;
    }
    .news-title-line {
      width: 200px;
      height: 12px;
      background-color: #4fbf35;
    }
  }
  .news-content {
    widows: 100%;
    height: calc(100% - 200px);
    .news-content-desc {
      height: 100%;
      width: 300px;
      float: left;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #AAAAAB;
      line-height: 27px;
      padding-top: 30px;
      padding-right: 55px;
    }
    .news-content-carousel {
      height: 100%;
      width: calc(100% - 300px);
      float: left;
      box-sizing: border-box;
      border-top: 1px solid #d4d4d4;
      padding-top: 30px;
      .carousel-main {
        width: 520px;
        height: 390px;
        float: left;
        margin-right: 28px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .carousel-list {
        width: calc(100% - 548px);
        height: 100%;
        float: left;
        padding-right: 11%;
        img {
          height: 180px;
          width: 240px;
          float: left;
          margin-right: 20px;
          cursor: pointer;
          margin-bottom: 28px;
        }
      }
    }
  }
}
.about-4 {
  background-color: #0015FF;
  position: relative;
  padding: 9% 0 0 17%;
  .news-title {
    width: 100%;
    height: 200px;
    .news-title-text {
      font-size: 85px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 15px;
    }
    .news-title-line {
      width: 200px;
      height: 12px;
      background-color: #4fbf35;
    }
  }
  .news-content {
    widows: 100%;
    height: calc(100% - 200px);
    .news-content-desc {
      height: 100%;
      width: 300px;
      float: left;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 27px;
      padding-top: 30px;
      padding-right: 55px;
    }
    .news-content-carousel {
      height: 100%;
      width: calc(100% - 300px);
      float: left;
      box-sizing: border-box;
      border-top: 1px solid #d4d4d4;
      padding-top: 30px;
      .carousel-main {
        width: 520px;
        height: 390px;
        float: left;
        margin-right: 28px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .carousel-list {
        width: calc(100% - 548px);
        height: 100%;
        float: left;
        padding-right: 11%;
        img {
          height: 180px;
          width: 240px;
          float: left;
          margin-right: 20px;
          cursor: pointer;
          margin-bottom: 28px;
        }
      }
    }
  }
}
.about-5 {
  background-color: #0514f5;
  position: relative;
  padding: 9% 0 0 17%;
  .news-title {
    width: 100%;
    height: 200px;
    .news-title-text {
      font-size: 85px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 15px;
    }
    .news-title-line {
      width: 200px;
      height: 12px;
      background-color: #4fbf35;
    }
  }
  .news-content {
    widows: 100%;
    height: calc(100% - 200px);
    .news-content-desc {
      height: 100%;
      width: 300px;
      float: left;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 27px;
      padding-top: 30px;
      padding-right: 55px;
    }
    .news-content-carousel {
      height: 100%;
      width: calc(100% - 300px);
      float: left;
      box-sizing: border-box;
      border-top: 1px solid #d4d4d4;
      padding-top: 30px;
      .carousel-main {
        width: 520px;
        height: 390px;
        float: left;
        margin-right: 28px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .carousel-list {
        width: calc(100% - 548px);
        height: 100%;
        float: left;
        padding-right: 11%;
        img {
          height: 180px;
          width: 240px;
          float: left;
          margin-right: 20px;
          cursor: pointer;
          margin-bottom: 28px;
        }
      }
    }
  }
}
.about-6 {
  background-color: #e0dedf;
  position: relative;
  padding: 8% 0 0 17%;
  .news-title {
    width: 100%;
    height: 200px;
    .news-title-text {
      font-size: 85px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #0015FF;
      margin-bottom: 15px;
    }
    .news-title-line {
      width: 200px;
      height: 12px;
      background-color: #4fbf35;
     
    }
  }
  .news-content {
    widows: 100%;
    height: calc(100% - 200px);
    // .news-content-desc {
    //   height: 100%;
    //   width: 300px;
    //   float: left;
    //   font-size: 16px;
    //   font-family: Source Han Sans SC;
    //   font-weight: 400;
    //   color: #ffffff;
    //   line-height: 27px;
    //   padding-top: 30px;
    //   padding-right: 55px;
    // }
    .news-content-carousel {
      height: 100%;
      width: 100%;
      float: left;
      box-sizing: border-box;
      border-top: 1px solid #d4d4d4;
      padding-top: 30px;
      .carousel-main {
        width: 620px;
        height: 460px;
        float: left;
        margin-right: 28px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .carousel-list {
        width: calc(100% - 648px);
        height: 100%;
        float: left;
        padding-right: 6%;
        img {
          height: 130px;
          width: 200px;
          float: left;
          margin-right: 20px;
          cursor: pointer;
          margin-bottom: 28px;
        }
      }
    }
  }
}
.about-title {
  width: 770px;
  height: 200px;
  position: absolute;
  left: 15%;
  top: 40%;
  user-select: none;
  .about-title-text {
    width: 100%;
    // height: 160px;
    // line-height: 180px;
    color: #fff;
    font-size: 90px;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .about-title-base {
    width: 175px;
    height: 12px;
    background-color: #4fbf35;
    // margin-left: 8px;
  }
}
.about-title2 {
  width: 755px;
  height: 350px;
  position: absolute;
  left: 10%;
  top: 13%;
  user-select: none;
  .about-title2-text {
    width: 100%;
    color: #0015FF;
    font-size: 80px;
    font-weight: 600;
  }
  .about-title2-base {
    width: 140px;
    height: 12px;
    background-color: #4fbf35;
    margin-left: 8px;
    margin-bottom: 48px;
    
  }
  .about-title2-descrpt {
    width: 100%;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #0015FF;
    line-height: 27px;
    padding-right: 318px;
    padding-left: 8px;
  }
}
.about-title3 {
  width: 74%;
  height: 200px;
  position: absolute;
  left: 13%;
  top: 2%;
  user-select: none;
  .about-t-title-content {
    height: 100%;
    width: 50%;
    float: left;
    .about-title3-text {
      width: 755px;
      color: #0015FF;
      font-size: 80px;
      font-weight: 600;
      line-height: 80px;
      margin-bottom: 16px;
      padding-top: 10px;
    }
    .about-title3-base {
      width: 140px;
      height: 12px;
      background-color: #4fbf35;
      margin-left: 8px;
      margin-bottom: 48px;
    }
  }
  .about-title3-descrpt {
    width: 50%;
    height: 100%;
    font-size: 30px;
    font-family: SourceHanSansSC;
    font-weight: 500;
    color: #0015FF;
    line-height: 40px;
    float: right;
    padding: 25px 0 0 25%;
  }
}
.about-descript {
  width: 740px;
  height: 200px;
  position: absolute;
  top: 60%;
  left: 15%;
  font-size: 17px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 27px;
}
.about-left-and-right {
  width: 200px;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgb(49, 49, 255);
  padding: 0 50px;
  .left-arrow {
    width: 40px;
    height: 40px;
    float: left;
    background-image: url("@/assets/Icons/left-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
  .right-arrow {
    width: 40px;
    height: 40px;
    float: right;
    background-image: url("@/assets/Icons/right-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
}
.news {
  .el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .el-carousel__item:nth-child(2n+1) {
        // background-color: #d3dce6;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators {// 指示器
        left: unset;
        transform: unset;
        right: 2%;
    }
    /deep/ .el-carousel__button {// 指示器按钮
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {// 指示器激活按钮
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators--vertical {
      left: 45px;
      top: 80%;
      width: 20px;
    }
  }
}
.about-4 {
  .el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .el-carousel__item:nth-child(2n+1) {
        // background-color: #d3dce6;
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators {// 指示器
        left: unset;
        transform: unset;
        right: 2%;
        display: none;
    }
    /deep/ .el-carousel__button {// 指示器按钮
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {// 指示器激活按钮
        background: red;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators--vertical {
      left: 45px;
      top: 80%;
      width: 20px;
    }
  }
}
.about-more-btn {
  position: absolute;
  right: 60px;
  bottom: 40px;
  width: 60px;
  height: 60px;
  background-image: url("@/assets/Icons/about-menu.png");
  background-size: 100% 100%;
  // display: block;
  cursor: pointer;
  -moz-transition: all .5s ease-out, opacity 0.5s ease-out;
  -o-transition: all .5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all .5s ease-out, opacity 0.5s ease-out;
  transition: all .5s ease-out, opacity 0.5s ease-out;
}
.about-more-btn-close {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 0px;
  background-color: #0515ef;
  z-index: 7;
  -moz-transition: all .5s ease-out, opacity 0.5s ease-out;
  -o-transition: all .5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all .5s ease-out, opacity 0.5s ease-out;
  transition: all .5s ease-out, opacity 0.5s ease-out;
  border-radius: 100px;
}
.about-more-btn-open {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 40%;
  height: 360px;
  background-color: #0515ef;
  z-index: 7;
  border-radius: 1px;
}
.about-more-btn-col {
  height: 180px;
  width: 100%;
  background-color: #0515ef;
  -moz-transition: all .5s ease-out, opacity 0.5s ease-out;
  -o-transition: all .5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all .5s ease-out, opacity 0.5s ease-out;
  transition: all .5s ease-out, opacity 0.5s ease-out;
  // border-radius: 100px;
  font-size: 36px;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 180px;
  padding-left: 68px;
  box-sizing: border-box;
  cursor: pointer;
}
.about-more-btn-col-hide {
  height: 0px;
  width: 0px;
  background-color: #0515ef;
  border-radius: 100px;
  -moz-transition: all .5s ease-out, opacity 0.5s ease-out;
  -o-transition: all .5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all .5s ease-out, opacity 0.5s ease-out;
  transition: all .5s ease-out, opacity 0.5s ease-out;
  border-radius: 100px;
}
.click-show-menu {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 40%;
  height: 640px;
  // background-color: #0515ef;
  z-index: 5;
  border-radius: 1px;
}
</style>
<style  scoped>
.myCarousel >>> .el-carousel .el-carousel__indicators--vertical {
  left: 45px;
  top: 80%;
  width: 20px;
}
.trends-title {
  width: 100%;
  color: #fff;
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 15px;
  -webkit-animation: 8s wobble linear infinite;
  animation: 8s wobble linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
#newstitle2 {
  font-size: 85px;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #0015FF;
  margin-bottom: 15px;
  position: relative;
}
#newstitle3 {
  font-size: 85px;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #0015FF;
  margin-bottom: 15px;
}
#newstitle4 {
  font-size: 85px;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}
#newstitle5 {
  font-size: 85px;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}
#newstitle6 {
  font-size: 85px;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #0015FF;
  margin-bottom: 15px;
}
.trends-title2 {
  -webkit-animation: 8s wobble linear infinite;
  animation: 8s wobble linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
.trends-desc {
  -webkit-animation: 2s descFrames linear infinite;
  animation: 2s descFrames linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
#newsdesc {
  width: 740px;
  height: 200px;
  position: absolute;
  top: 60%;
  left: 15%;
  font-size: 17px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 27px;
}
#newsdesc2 {
  height: 100%;
  width: 300px;
  float: left;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #AAAAAB;
  line-height: 27px;
  padding-top: 30px;
  padding-right: 55px;
}
#newsdesc3 {
  height: 100%;
  width: 300px;
  float: left;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #AAAAAB;
  line-height: 27px;
  padding-top: 30px;
  padding-right: 55px;
}
#newsdesc4 {
  height: 100%;
  width: 300px;
  float: left;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 27px;
  padding-top: 30px;
  padding-right: 55px;
}
#newsdesc5 {
  height: 100%;
  width: 300px;
  float: left;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 27px;
  padding-top: 30px;
  padding-right: 55px;
}
.trends-desc2 {
  -webkit-animation: 2s descFrames2 linear infinite;
  animation: 2s descFrames2 linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
@keyframes wobble {
  0% {
    transform: scale(0, 0);
  }
  1% {
    transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    transform: scale(0.96274018852263, 1.006488198218787);
  }
  18% {
    transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    transform: scale(1.008821093113781, 0.999833501948366);
  }
  25% {
    transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    transform: scale(0.999833501948366, 1.000014970213524);
  }
  37% {
    transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    transform: scale(0.999930953553717, 1.000000476269022);
  }
  47% {
    transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    transform: scale(1.000000687807928, 1.00000000065078);
  }
  71% {
    transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    transform: scale(1.000000456728239, 0.999999999724765);
  }
  74% {
    transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    transform: scale(1.000000000606774, 1.000000000000103);
  }
}
@keyframes descFrames {
  0% {
    top: 100%;
    opacity: 0;
  }
  50% {
    top: 70%;
    opacity: .5;
  }
  100% {
    top: 60%;
    opacity: 1;
  }
}
@keyframes descFrames2 {
  0% {
    padding-top: 130px;
    opacity: 0;
  }
  50% {
    padding-top: 40px;
    opacity: .5;
  }
  100% {
    padding-top: 30px;
    opacity: 1;
  }
}
.show-image-name {
  transition: all 0.4s;
}
.show-image-name:hover {
  /* box-shadow: 0 8px 8px 0 grey; */
  transform: scale(1.1,1.1);
  border-radius: 10px;
}
.newstitle2-2 {
  position: absolute;
  width: 900px;
  height: 119px;
  left: 254px;
  top: 80px;
}
</style>