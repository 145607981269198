<template>
  <div class="menu">
    <el-dialog
      class="menu-dialig"
      :fullscreen="true"
      :visible.sync="visible"
    >
      <div class="menu-content" :class="{ 'menu-content-bg': hide}">
        <div v-if="hide" class="about-logo"></div>
        <div v-if="hide" class="menu-tips">
          <div class="language-select">
            <div class="options" :class="{ 'select-option': language == 'chinese' }">
              <span @click="handleSelectLanguage('chinese')">{{ $t("bilingual0060") }}</span>
            </div>
            <div class="options" :class="{ 'select-option': language == 'english' }">
              <span @click="handleSelectLanguage('english')">{{ $t("bilingual0061") }}</span>
            </div>
          </div>
          <div class="tips-1">+86 24 8105 5099 </div>
          <div class="tips-2">{{ $t('bilingual0077') }}</div>
          <div class="tips-2">{{ $t('bilingual0078') }}</div>
          <div class="tips-2">{{ $t('bilingual0079') }}</div>
          <div class="tips-2">{{ $t('bilingual0080') }}</div>
        </div>
        <div v-if="hide" class="menu-list">
          <div class="menu-list-colum">
            <div class="menu-list-colum-index" :class="{ 'menu-list-colum-active': ( routerName == '/' || routerName == '/home' ) }">H</div>
            <div class="menu-list-colum-text">
              <span :class="{ 'menu-list-colum-text-active': ( routerName == '/' || routerName == '/home' ) }" @click="handleJumpRouter('/home')">{{ $t("HOME") }}</span>
            </div>
          </div>
          <div class="menu-list-colum">
            <div class="menu-list-colum-index" :class="{ 'menu-list-colum-active': ( routerName == '/about' ) }">01</div>
            <div class="menu-list-colum-text">
              <span :class="{ 'menu-list-colum-text-active': ( routerName == '/about' ) }" @click="handleJumpRouter('/about')">{{ $t("bilingual0019") }}</span>
            </div>
          </div>
          <div class="menu-list-colum">
            <div class="menu-list-colum-index" :class="{ 'menu-list-colum-active': (routerName == '/product' ) }">02</div>
            <div class="menu-list-colum-text">
              <span :class="{ 'menu-list-colum-text-active': ( routerName == '/product' ) }" @click="handleJumpRouter('/product')">{{ $t("bilingual0020") }}</span>
            </div>
          </div>
          <div class="menu-list-colum">
            <div class="menu-list-colum-index" :class="{ 'menu-list-colum-active': ( routerName == '/partner' ) }">03</div>
            <div class="menu-list-colum-text">
              <span :class="{ 'menu-list-colum-text-active': ( routerName == '/partner' ) }" @click="handleJumpRouter('/partner')">{{ $t("bilingual0021") }}</span>
            </div>
          </div>
          <div class="menu-list-colum">
            <div class="menu-list-colum-index" :class="{ 'menu-list-colum-active': ( routerName == '/news' ) }">04</div>
            <div class="menu-list-colum-text">
              <span :class="{ 'menu-list-colum-text-active': ( routerName == '/news' ) }" @click="handleJumpRouter('/news')">{{ $t("bilingual0022") }}</span>
            </div>
          </div>
          <div class="menu-list-colum">
            <div class="menu-list-colum-index" :class="{ 'menu-list-colum-active': ( routerName == '/contacts' ) }">05</div>
            <div class="menu-list-colum-text">
              <span :class="{ 'menu-list-colum-text-active': ( routerName == '/contacts' ) }" @click="handleJumpRouter('/contacts')">{{ $t("bilingual0023") }}</span>
            </div>
          </div>
        </div>
        <div class="season season1" :class="{ 'active': show1, 'inactive': hide }"></div>
        <div class="season season2" :class="{ 'active': show2, 'inactive': hide }"></div>
        <div class="season season3" :class="{ 'active': show3, 'inactive': hide }"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import i18n from '@/lang'
export default {
  name: 'Menu',
  data() {
    return {
      visible: false,
      language: localStorage.getItem('language') == 'zh' ? 'chinese' : 'english',
      show1: false,
      show2: false,
      show3: false,
      hide: false,
    }
  },
  computed: {
    routerName() {
      return this.$route.path
    }
  },
  methods: {
    handleShow() {
      this.visible = true;
      // this.show1 = true;
      let _this = this
      setTimeout(() => {
        _this.show1= true;
      },100)
      setTimeout(() => {
        _this.show2= true;
      },200)
      setTimeout(() => {
        _this.show3= true;
      },300)
      setTimeout(() => {
        _this.hide= true;
      },500)
    },
    handleHide() {
      this.hide= false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.visible = false;
    },
    handleJumpRouter(path) {
      this.handleHide()
      if(this.routerName != path) {
        this.$router.push({
          path: path
        })
      }else{
        this.$parent.doInit();
      }
    },
    handleSelectLanguage(language) {
      this.language = language
      if(language == 'chinese') {
        i18n.locale = 'zh'
        localStorage.setItem("language",'zh')
      }else{
        i18n.locale = 'en'
        localStorage.setItem("language",'en')
      }
      //跳转到首页
      this.handleJumpRouter('/')
      
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-content {
  height: 100vh;
  width: 100%;
  
  padding: 0 15%;
  position: relative;
  .menu-list {
    width: 70%;
    height: 600px;
    // background-color: red;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    .menu-list-colum {
      height: 100px;
      width: 600px;
      // background-color: yellowgreen;
      line-height: 100px;
      .menu-list-colum-index {
        width: 47px;
        height: 47px;
        float: left;
        font-size: 24px;
        font-family: SourceHanSansSC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 47px;
        text-align: center;
        border-radius: 30px;
        border: 1px solid #FFFFFF;
        margin-top: 26px;
        margin-right: 30px;
      }
      .menu-list-colum-active {
        color: #0514f5;
        background-color: #fff;
      }
      .menu-list-colum-text {
        font-size: 48px;
        font-family: SourceHanSansSC;
        // font-weight: bold;
        color: #FFFFFF;
        .menu-list-colum-text-active {
          text-decoration: underline;
        }
        span:hover {
          // text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .menu-tips {
    width: 500px;
    height: 300px;
    position: absolute;
    right: 7%;
    bottom: 10%;
    z-index: 4;
    .language-select {
      widows: 100%;
      height: 40px;
      margin-bottom: 35px;
      .options {
        width: 100px;
        height: 100%;
        float: left;
        font-size: 24px;
        font-family: SourceHanSansSC;
        color: #FFFFFF;
        line-height: 35px;
        margin-right: 40px;
        span {
          cursor: pointer;
        }
      }
      .select-option {
        border-bottom: 3px solid #4fbf35;
      }
    }
    .tips-1 {
      font-size: 18px;
      font-family: SourceHanSansSC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 24px;
      margin-bottom: 15px;
    }
    .tips-2 {
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      margin-bottom: 5px;
    }
  }
}
.menu-content-bg {
  background-image: url("@/assets/BigImage/menu-bg.jpg");
  background-size: 100% 100%;
}
.menu {
  /deep/ .el-dialog__body {
    padding: 0;
  }
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__headerbtn {
    display: none;
  }
}
.about-logo {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-write.png");
  background-size: 100% 100%;
  cursor: pointer;
}
</style>
<style>
.dialog-fade-enter-active {
  animation: dialog-fade-in .1s;
}
.dialog-fade-leave-active {
  animation: dialog-fade-out .1s;
}
@keyframes dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
}
.season {
  position: absolute;
  width: 0;
  height: 33%;
  display: block;
  z-index: 1;
  background-color: #042d7d;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  -moz-transition: all .2s ease-out, opacity 0.3s ease-out;
  -o-transition: all .2s ease-out, opacity 0.3s ease-out;
  -webkit-transition: all .2s ease-out, opacity 0.3s ease-out;
  transition: all .2s ease-out, opacity 0.3s ease-out;
}
.season1 {
  left: 0;
  top: 0%;
}
.season2 {
  left: 0;
  top: 33%;
}
.season3 {
  left: 0;
  top: 66%;
}
.active {
  width: 100%;
  z-index: 1;
}
.inactive {
  opacity: 0;
  z-index: 1;
}
</style>