const state = {
    routerArr: {
        about: {
            next: '/product',
            prev: null,
        },
        product: {
            next: '/partner',
            prev: '/about',
        },
        partner: {
            next: '/news',
            prev: '/product',
        },
        news: {
            next: '/contacts',
            prev: '/partner',
        },
        contacts: {
            next: null,
            prev: '/news',
        }
    },
    nowRouter: 'about'
}
const mutations = {
    SET_NOWROUTER: (state, nowRouter) => {
        state.nowRouter = nowRouter
    },
    GET_ROUTERLOCAL: (state) => {
        return state.routerArr[state.nowRouter]
    }
}
const actions = {
    switchRouter({ commit }, nowRouter) {
        return new Promise((resolve, reject) => {
            commit('SET_NOWROUTER', nowRouter)
            resolve(state.routerArr[nowRouter])
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}