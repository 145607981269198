import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import axios from 'axios';
Vue.prototype.axios = axios;
import { Button, Select, Backtop, Carousel, CarouselItem, Input, Collapse, CollapseItem, Dialog, Loading, Image, Table, TableColumn } from 'element-ui';
Vue.config.productionTip = false
Vue.use(Button);
Vue.use(Select);
Vue.use(Backtop);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Input);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Dialog);
Vue.use(Image);
Vue.use(Loading.directive)
Vue.use(Table)
Vue.use(TableColumn)
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')