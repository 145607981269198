<template>
  <div class="about">
    <el-carousel
      ref="carouselRef"
      :initial-index="0"
      class="myHomeCarousel"
      arrow="always"
      :height="screenHeight + 'px'"
      direction="horizontal"
      :loop="false"
      :autoplay="false"
      @change="handleChangeCarousel"
    >
      <el-carousel-item name="0">
        <div class="about-card about-1" @click="handleTurnSecond('/about')">
          <div class="about-logo" @click.stop=""></div>
          <div class="about-btns" @click.stop="handleShowMenu"></div>
          <div class="home-index">01</div>
          <div class="about-title">
            <div id="home1title" class="about-title-text">
              {{ $t("bilingual0001") }}
            </div>
            <div class="about-title-base"></div>
          </div>
          <div id="realhome1desc" class="about-descript">
            <div style="margin-bottom: 20px">{{ $t("bilingual0002") }}</div>
            <div>{{ $t("bilingual0003") }}</div>
          </div>
          <div class="about-left-and-right" @click.stop="">
            <div class="left-arrow" @click.stop="handlePrevRouter">
              <div class="arrow-img"></div>
            </div>
            <div class="right-arrow" @click.stop="handleNextRouter">
              <div class="arrow-img"></div>
            </div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="1">
        <div class="about-card about-2">
          <div class="about-logo"></div>
          <div class="about-btns" @click="handleShowMenu"></div>
          <div class="home-index">02</div>
          <div class="about-left-and-right">
            <div class="left-arrow" @click.stop="handlePrevRouter">
              <div class="arrow-img"></div>
            </div>
            <div class="right-arrow" @click.stop="handleNextRouter">
              <div class="arrow-img"></div>
            </div>
          </div>
          <div class="home2-left">
            <div class="home2-left-content">
              <div>{{ $t("bilingual0075") }}</div>
              <div>{{ $t("bilingual0076") }}</div>
            </div>
          </div>
          <div class="home2-right">
            <div class="home2-right-menu">
              <div
                class="home2-right-menu-col"
                style="border-bottom: 1px solid #b4b4b4"
                @click="handleTurnSecond('/product')"
              >
                <!-- <div class="main-menu-name">{{ $t("bilingual0066") }}</div>
                <div class="second-menu-name">{{ $t("bilingual0070") }}</div> -->
                <div class="main-menu-name">{{ $t("bilingual0067") }}</div>
                <div class="second-menu-name">{{ $t("bilingual0070") }}</div>
              </div>

              <div
                class="home2-right-menu-col"
                style="border-bottom: 1px solid #b4b4b4"
                @click="handleTurnSecond('/product')"
              >
                <!-- <div class="main-menu-name">{{ $t("bilingual0067") }}</div>
                <div class="second-menu-name">{{ $t("bilingual0070") }}</div> -->
                <div class="main-menu-name">{{ $t("bilingual0068") }}</div>
                <div class="second-menu-name">{{ $t("bilingual0070") }}</div>
              </div>

              <div
                class="home2-right-menu-col"
                style="border-bottom: 1px solid #b4b4b4"
                @click="handleTurnSecond('/product')"
              >
                <!-- <div class="main-menu-name">{{ $t("bilingual0068") }}</div>
                <div class="second-menu-name">{{ $t("bilingual0070") }}</div> -->
                <div class="main-menu-name">{{ $t("bilingual0066") }}</div>
                <div class="second-menu-name">{{ $t("bilingual0070") }}</div>
              </div>

              <div class="home2-right-menu-col" @click="handleTurnSecond('/product')">
                <div class="main-menu-name">{{ $t("bilingual0085") }}</div>
                <div class="second-menu-name">{{ $t("bilingual0070") }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="2">
        <div class="about-card about-3">
          <div class="about-logo"></div>
          <div class="about-btns" @click.stop="handleShowMenu"></div>
          <div class="home-index">03</div>
          <div class="about-left-and-right">
            <div class="left-arrow" @click.stop="handlePrevRouter">
              <div class="arrow-img"></div>
            </div>
            <div class="right-arrow" @click.stop="handleNextRouter">
              <div class="arrow-img"></div>
            </div>
          </div>
          <div class="about-3-left" @click="handleTurnSecond('/partner')">
            <div class="about-3-left-content">
              <div class="about-3-left-content-text">
                <div style="font-weight: 600; font-size: 22px">2023.1</div>
                <div>{{ $t("bilingual0010") }}</div>
              </div>
              <div class="about-3-left-content-img">
                <img src="http://vh-marinetech.com/upload/portal/20230417/278a82e13336d2557a03cb6b002dfc70.png" alt="" />
              </div>
            </div>
          </div>
          <div class="about-3-right" @click="handleTurnSecond('/partner')">
            <div class="about-3-right-content">
              <div id="home3titles" class="about-3-right-content-col1">{{ $t("bilingual0071") }}</div>
              <div class="about-3-right-content-col2"></div>
              <div id="home3descs" class="about-3-right-content-col3">{{ $t("bilingual0072") }}</div>
            </div>
          </div>
          <div class="about-3-logos-content">
            <div class="about-3-logos-content-left" @click="handlePrewLogo"></div>
            <div class="about-3-logos-content-center">
              <div id="home-cards-content" class="about-3-logos-cards">
                <div v-for="(item, index) in logosArr" :key="index" class="about-3-logos-card">
                  <img :src="item.img" alt="" />
                </div>
              </div>
            </div>
            <div class="about-3-logos-content-right" @click="handleNextLogo"></div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="3">
        <div class="about-card about-4" @click="handleTurnSecond('/news')">
          <div class="about-logo"></div>
          <div class="about-btns" @click.stop="handleShowMenu"></div>
          <div class="home-index">04</div>
          <div class="about-left-and-right">
            <div class="left-arrow" @click.stop="handlePrevRouter">
              <div class="arrow-img"></div>
            </div>
            <div class="right-arrow" @click.stop="handleNextRouter">
              <div class="arrow-img"></div>
            </div>
          </div>
          <div class="about-4-left">
            <div class="about-title">
              <div id="home4titles" class="about-title-text">{{ $t("bilingual0050") }}</div>
              <div class="about-title-base"></div>
            </div>
            <div id="home4descs" class="about-descript">
              <div style="margin-bottom: 20px">{{ $t("bilingual0073") }}</div>
              <div>{{ $t("bilingual0074") }}</div>
            </div>
          </div>
          <div class="about-4-right"></div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <Menu ref="menu" />
  </div>
</template>
<script>
// import { debounce } from "@/utils"
import Menu from "@/components/menu.vue";
// import logo21 from "@/assets/Logo/logo21.png";
// import logo23 from "@/assets/Logo/logo23.png";
// import logo45 from "@/assets/Logo/logo45.png";
// import logo1 from "@/assets/Logo/logo1.png";
// import logo46 from "@/assets/Logo/logo46.png";
// import logo24 from "@/assets/Logo/logo24.png";
// import logo22 from "@/assets/Logo/logo22.png";
// import logo32 from "@/assets/Logo/logo32.png";
// import logo42 from "@/assets/Logo/logo42.png";
// import logo34 from "@/assets/Logo/logo34.png";
import { myAddEvent, getByClass } from "@/utils/base"
import { ScrollImg } from "@/utils/scrollImg"
import "@/utils/directive";
var carouselTimer = null;
var that;
export function scrollImg(){
  var aPicList=getByClass(document,'pic-list');
  var i=0;
  function next(){
    if(i < aPicList.length){
      new ScrollImg(aPicList[i]);
      let timer = null
      timer=setTimeout(next, 1000);
    }
    i++;
  }
  function doNext() {
    if(i < aPicList.length){
      --i
      new ScrollImg(aPicList[i]);
    }
  }
  next();
}

export default {
  name: "home",
  components: {
    Menu,
  },
  data() {
    return {
      itemIndex: 0,
      about5ImageWidth: 0,
      menuOpen: false,
      openLock: true,
      stop: false,
      logosArr: [
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo21.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo23.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo45.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo1.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo46.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo24.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo22.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo32.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo42.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo34.png",
        },{
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo21.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo23.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo45.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo1.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo46.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo24.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo22.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo32.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo42.png",
        },
        {
          img: "http://www.vh-marinetech.com/themes/simpleboot3/portal/img/logo34.png",
        },
      ],
      about5Left: 0,
    };
  },
  computed: {
    screenHeight() {
      var clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight;
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight;
      }
      return clientHeight;
    },
  },
  // home-cards-content
  watch: {
    about5ImageWidth(val1, val2) {
      console.log(93, val1);
    },
    itemIndex(val1,val2) {
      console.log(181, val1)
      var _el = document.getElementById("home-cards-content");
      // if(val1 == '2') {
      //   carouselTimer = setInterval(function(){
      //     that.about5Left = that.about5Left - 5;
      //     _el.style.left = that.about5Left + 'px'
      //   },100);
      // }else{
      //   _el.style.left= '0';
      //   that.about5Left = 0;
      //   clearInterval(carouselTimer);
      // }
      if(val1 == '0') {
        setTimeout(() => {
          document.getElementById("home1title").className="trends-title";
          document.getElementById("realhome1desc").className="trends-desc1";
        },300)
      }else{
        document.getElementById("home1title").className="about-title-text";
        document.getElementById("realhome1desc").className="about-descript";
      }

      if(val1 == '2') {
        setTimeout(() => {
          document.getElementById("home3titles").className = "trends-title2";
          document.getElementById("home3descs").className="trends-desc2";
        },300)
      }else{
        document.getElementById("home3titles").className="about-3-right-content-col1";
        document.getElementById("home3descs").className="about-3-right-content-col3";
      }

      if(val1 == '3') {
        setTimeout(() => {
          document.getElementById("home4titles").className = "trends-title3";
          document.getElementById("home4descs").className="trends-desc3";
        },300)
      }else{
        document.getElementById("home4titles").className="about-title-text";
        document.getElementById("home4descs").className="about-descript";
      }
    }
  },
  mounted() {
    this.doInit();
    that = this;
    setTimeout(() => {
      document.getElementById("realhome1desc").className="trends-desc1";
      document.getElementById("home1title").className="trends-title";
    },300)
    myAddEvent(window,'load',this.scrollImg);
  },
  beforeDestroy() {
    document.getElementById("home1title").className="";
    clearInterval(carouselTimer);
  },
  methods: {
    doInit() {
      this.axios({
        url: '/api/index/getList',
        method: 'get',
        params: {
          category: '3',
          lang: (localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"),
        }
      }).then(res => {
        console.log(347, res)
      })
    },
    scrollImg(){
      let _this = this;
      var aPicList=getByClass(document,'pic-list');
      var i = 0;
      function next(){
        if(i < aPicList.length && !_this.stop){
          new ScrollImg(aPicList[i]);
          setTimeout(() => {
            next();
          },1000)
        }
        i++;
      }
      next();
    },
    handleChangeCarousel(newValue, oldValue) {
      this.itemIndex = newValue;
    },
    handlePrevRouter() {
      this.$refs.carouselRef?.prev();
    },
    handleNextRouter() {
      this.$refs.carouselRef?.next();
    },
    handleShowMenu() {
      console.log(243);
      this.$refs.menu.handleShow();
    },
    handleOutSide() {
      if (!this.openLock) {
        this.menuOpen = false;
        this.openLock = true;
      }
    },
    handleShowBottomMenu() {
      this.menuOpen = true;
      let _this = this;
      setTimeout(() => {
        _this.openLock = false;
      }, 200);
    },
    handleJumpCards(index) {
      this.$refs.carouselRef.setActiveItem(index);
      this.handleOutSide();
    },
    handleActionCarousel(action) {
      if (action == "left") {
        this.$refs.about4Carousel.prev();
      } else {
        this.$refs.about4Carousel.next();
      }
    },
    handleTurnSecond(path) {
      this.$router.push({
        path: path,
      });
    },
    handlePrewLogo() {
      let _el = document.getElementById("home-cards-content");
      console.log(332, _el.style)
      if(this.about5Left<=-3372) return false
      this.about5Left = this.about5Left - 281
      _el.style.left = (this.about5Left ) + 'px'
    },
    handleNextLogo() {
      let _el = document.getElementById("home-cards-content");
      if (this.about5Left >= 0) return false
      this.about5Left = this.about5Left + 281
      _el.style.left = (this.about5Left) + 'px'
    },
  },
};
</script>
<style lang="scss" scoped>
.about {
  width: 100%;
}
.about-card {
  width: 100%;
  height: 100vh;
  position: relative;
}
.about-logo {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-write.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.about-logo2 {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-blue.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.about-btns {
  width: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
  z-index: 5;
}
.about-btns2 {
  width: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more-blue3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
}
.about-next-card {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn.png");
  background-size: 100% 100%;
  opacity: 0.4;
  z-index: 9;
}
.about-next-card:hover {
  cursor: pointer;
  opacity: 0.9;
}
.about-more-btn {
  position: absolute;
  right: 60px;
  bottom: 40px;
  width: 60px;
  height: 60px;
  background-image: url("@/assets/Icons/about-menu.png");
  background-size: 100% 100%;
  // display: block;
  cursor: pointer;
  -moz-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  -o-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  transition: all 0.5s ease-out, opacity 0.5s ease-out;
}
.about-more-btn-close {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 0px;
  background-color: #0515ef;
  z-index: 1;
  -moz-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  -o-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  transition: all 0.5s ease-out, opacity 0.5s ease-out;
  border-radius: 100px;
}
.about-more-btn-open {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 40%;
  height: 720px;
  background-color: #0515ef;
  z-index: 5;
  border-radius: 1px;
}
.about-more-btn-col {
  height: 240px;
  width: 100%;
  background-color: #0515ef;
  -moz-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  -o-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  transition: all 0.5s ease-out, opacity 0.5s ease-out;
  // border-radius: 100px;
  font-size: 36px;
  font-family: SourceHanSansSC;
  font-weight: bold;
  color: #ffffff;
  line-height: 240px;
  padding-left: 68px;
  box-sizing: border-box;
  cursor: pointer;
}
.about-more-btn-col-hide {
  height: 0px;
  width: 0px;
  background-color: #0515ef;
  border-radius: 100px;
  -moz-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  -o-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out, opacity 0.5s ease-out;
  transition: all 0.5s ease-out, opacity 0.5s ease-out;
  border-radius: 100px;
}
.about-next-card2 {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn2.png");
  background-size: 100% 100%;
  opacity: 0.4;
  z-index: 9;
}
.about-next-card2:hover {
  cursor: pointer;
  opacity: 0.9;
}
.about-1 {
  background-image: url("@/assets/BigImage/about-1-1.jpg");
  background-size: 100% 100%;
}
.about-2 {
  background-color: #001f3e;
  position: relative;
  .box {
    height: 100%;
    margin: 0 auto;
    .img-box {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
    .img-box img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      margin: auto;
      z-index: -1;
      zoom: 1;
    }
    .img-box:before {
      content: "";
      display: inline-block;
      padding-bottom: 100%;
      width: 0.1px;
      vertical-align: middle;
    }
  }
  .time-line {
    position: absolute;
    width: 100%;
    height: 60%;
    top: 31%;
    left: 0;
    // background-color: red;
    z-index: 2;
    .time-line-range {
      height: 67px;
      width: 100%;
      line-height: 67px;
      font-size: 24px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 29px;
      .year-start {
        width: 30%;
        float: left;
        padding-left: 70px;
        text-align: left;
      }
      .time-line-title {
        width: 40%;
        float: left;
        text-align: center;
        font-size: 48px;
        font-family: SourceHanSansSC;
        font-weight: bold;
        color: #51c934;
      }
      .end-start {
        width: 30%;
        float: right;
        padding-right: 70px;
        text-align: right;
      }
    }
    .about-2-img {
      width: calc(100% - 44px);
      margin: 0 22px;
      height: 290px;
      margin-bottom: 3%;
    }
    .about-2-3 {
      width: 100%;
      height: 35%;
      .new-year {
        width: 20%;
        height: 120px;
        float: left;
        margin-top: 3%;
        margin-left: 10%;
        font-size: 140px;
        font-family: SourceHanSansSC;
        font-weight: bold;
        color: #ffffff;
        line-height: 120px;
        text-align: right;
      }
      .descrpt-text {
        width: 27%;
        // height: 220px;
        float: right;
        // background-color: burlywood;
        margin-top: 2%;
        margin-right: 4%;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 27px;
        overflow: auto;
      }
    }
  }
}
.about-3 {
  position: relative;
  background-color: #ffffff;
  .about-3-left {
    width: 50%;
    height: calc(100% - 240px);
    float: left;
    background-color: #001f3e;
    .about-3-left-content {
      width: 260px;
      height: 210px;
      margin: 0 auto;
      margin-top: 30%;
      .about-3-left-content-text {
        width: 100%;
        height: 100px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        color: #ffffff;
        line-height: 33px;
        // background-color: red;
      }
      .about-3-left-content-img {
        width: 223px;
        height: auto;
        margin: 0 auto;
        margin-top: 45px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 19px;
        }
      }
    }
  }
  .about-3-right {
    width: 50%;
    height: calc(100% - 240px);
    float: left;
    background-image: url("@/assets/BigImage/part-main-1.jpg");
    background-size: 100% 100%;
    .about-3-right-content {
      width: 250px;
      height: 100%;
      // background-color: red;
      margin: 0 auto;
      padding-top: 27%;
      .about-3-right-content-col1 {
        font-size: 85px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
      }
      .about-3-right-content-col2 {
        width: 150px;
        height: 12px;
        background-color: #4fbf35;
        margin-left: 50px;
      }
      .about-3-right-content-col3 {
        width: 190px;
        color: #ffffff;
        font-size: 19px;
        text-align: center;
        margin-top: 30px;
        margin-left: 30px;
      }
    }
  }
  .about-3-logos-content {
    width: 100%;
    height: 200px;
    // background-color: red;
    float: left;
    padding: 67px 65px;
    .about-3-logos-content-left {
      width: 75px;
      height: 46px;
      float: left;
      background-image: url("@/assets/Icons/about5-left.png");
      background-size: 100% 100%;
      margin-top: 10px;
      margin-right: 75px;
      cursor: pointer;
    }
    .about-3-logos-content-center {
      height: 100%;
      width: calc(100% - 330px);
      // background-color: red;
      float: left;
      position: relative;
      overflow: hidden;
      .about-3-logos-cards {
        height: 100%;
        width: 200000px;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 17px;
        transition: 1s;
        .about-3-logos-card {
          width: 188px;
          height: 66px;
          margin: 0 25px;
          float: left;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .about-3-logos-content-right {
      width: 75px;
      height: 46px;
      float: left;
      background-image: url("@/assets/Icons/about5-right.png");
      background-size: 100% 100%;
      margin-top: 10px;
      margin-left: 75px;
      cursor: pointer;
    }
  }
}
.about-4 {
  // background-color: #e0dedf;
  // padding: 9% 15%;
  .about-4-images {
    width: 1340px;
    height: 718px;
    // background-color: #ffffff;
    // display: flex;
    // align-items: center;
    img {
      float: left;
      height: 351px;
    }
  }
  .about-4-btn-left {
    width: 75px;
    height: 46px;
    background-image: url("@/assets/Icons/about5-left.png");
    background-size: 100% 100%;
    cursor: pointer;
    position: absolute;
    left: 65px;
    top: 50%;
  }
  .about-4-btn-right {
    width: 75px;
    height: 46px;
    background-image: url("@/assets/Icons/about5-right.png");
    background-size: 100% 100%;
    cursor: pointer;
    position: absolute;
    right: 65px;
    top: 50%;
  }
}
.about-5 {
  background-color: #ffffff;
  padding-top: 30px;
  .about-5-top {
    height: 380px;
    width: 100%;
    // background-color: red;
    padding: 40px 50px 0 300px;
  }
  .about-5-seize {
    width: 100%;
    height: 240px;
  }
  .about-5-btns {
    height: 80px;
    width: 100%;
    padding: 0 60px;
    .about-5-btn-left {
      width: 92px;
      height: 56px;
      background-image: url("@/assets/Icons/about5-left.png");
      background-size: 100% 100%;
      float: left;
      cursor: pointer;
    }
    .about-5-btn-right {
      width: 92px;
      height: 56px;
      background-image: url("@/assets/Icons/about5-right.png");
      background-size: 100% 100%;
      float: right;
      cursor: pointer;
    }
  }
  .about-t-carousel {
    width: 100%;
    height: calc(100% - 320px);
    background-color: #0015ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .about-t-img {
      height: 100%;
      width: auto;
    }
    .about-t-name {
      height: 100%;
      width: auto;
      line-height: 25px;
      text-align: center;
      font-size: 19px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.about-title {
  width: 790px;
  height: 200px;
  position: absolute;
  left: 7%;
  top: 40%;
  user-select: none;
  .about-title-text {
    width: 100%;
    // height: 160px;
    // line-height: 180px;
    color: #fff;
    font-size: 80px;
    font-weight: 600;
    margin-bottom: 5px;
    span {
      -webkit-animation: 5s wobble linear infinite;
      animation: 5s wobble linear infinite;
      animation-iteration-count: 1;
      -webkit-animation-iteration-count: 1;
    }
  }
  .about-title-base {
    width: 147px;
    height: 12px;
    background-color: #4fbf35;
    // margin-left: 8px;
  }
}
.about-title2 {
  width: 655px;
  height: 350px;
  position: absolute;
  left: 16%;
  top: 14%;
  user-select: none;
  .about-title2-text {
    width: 100%;
    color: #0015ff;
    font-size: 80px;
    font-weight: 600;
  }
  .about-title2-base {
    width: 140px;
    height: 12px;
    background-color: #4fbf35;
    margin: 23px 0 48px 8px;
  }
  .about-title2-descrpt {
    width: 100%;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #0015ff;
    line-height: 27px;
    padding-right: 310px;
    padding-left: 8px;
  }
}
.about-title3 {
  width: 74%;
  height: 200px;
  position: absolute;
  left: 15%;
  top: 2%;
  user-select: none;
  .about-t-title-content {
    height: 100%;
    width: 50%;
    float: left;
    .about-title3-text {
      width: 755px;
      color: #0015ff;
      font-size: 80px;
      // font-weight: 600;
      line-height: 80px;
      margin-bottom: 16px;
      padding-top: 30px;
    }
    .about-title3-base {
      width: 140px;
      height: 12px;
      background-color: #4fbf35;
      margin-left: 8px;
      margin-bottom: 48px;
    }
  }
  .about-title3-descrpt {
    width: 50%;
    height: 100%;
    font-size: 30px;
    font-family: SourceHanSansSC;
    font-weight: 500;
    color: #0015ff;
    line-height: 40px;
    float: right;
    padding: 25px 0 0 23%;
  }
}
.about-descript {
  width: 420px;
  height: 200px;
  position: absolute;
  top: 75%;
  right: 10%;
  color: #fff;
  font-size: 16px;
}
.about-left-and-right {
  width: 200px;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgb(49, 49, 255);
  // padding: 0 50px;
  .left-arrow {
    width: 100px;
    height: 40px;
    float: left;
    // 72 × 28
    // width: 40px;
    // height: 16px;
    // float: left;
    // background-image: url("@/assets/Icons/home-left.png");
    // background-size: 100% 100%;
    // margin-top: 12px;
    cursor: pointer;
    padding-left: 50px;
    .arrow-img {
      width: 40px;
      height: 16px;
      background-image: url("@/assets/Icons/home-left.png");
      background-size: 100% 100%;
      margin-top: 12px;
    }
  }
  .right-arrow {
    width: 100px;
    height: 40px;
    float: left;
    padding-right: 50px;
    // width: 40px;
    // height: 16px;
    // float: right;
    // background-image: url("@/assets/Icons/home-right.png");
    // background-size: 100% 100%;
    // margin-top: 12px;
    .arrow-img {
      width: 40px;
      height: 16px;
      float: right;
      background-image: url("@/assets/Icons/home-right.png");
      background-size: 100% 100%;
      margin-top: 12px;
    }
    cursor: pointer;
  }
}
.about {
  .el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
      // background-color: #99a9bf;
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .el-carousel__item:nth-child(2n + 1) {
      // background-color: #d3dce6;
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators {
      // 指示器
      left: unset;
      transform: unset;
      right: 2%;
    }
    /deep/ .el-carousel__button {
      // 指示器按钮
      width: 10px;
      height: 10px;
      border: none;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {
      // 指示器激活按钮
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators--vertical {
      left: 45px;
      top: 80%;
      width: 20px;
    }
  }
}
.about-4 {
  .el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
      // background-color: #99a9bf;
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .el-carousel__item:nth-child(2n + 1) {
      // background-color: #d3dce6;
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators {
      // 指示器
      left: unset;
      transform: unset;
      right: 2%;
      display: none;
    }
    /deep/ .el-carousel__button {
      // 指示器按钮
      width: 10px;
      height: 10px;
      border: none;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {
      // 指示器激活按钮
      background: red;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators--vertical {
      left: 45px;
      top: 80%;
      width: 20px;
    }
  }
}
.home2-left {
  width: 50%;
  height: 100%;
  float: left;
  background-image: url("@/assets/BigImage/product-home3.png");
  background-size: 100% 100%;
  .home2-left-content {
    width: 300px;
    height: 80px;
    margin: auto;
    margin-top: calc(100% - 175px);
    color: #fff;
    text-align: center;
    font-size: 27px;
    line-height: 42px;
  }
}
.home2-right {
  width: 50%;
  height: 100%;
  float: left;
  background-image: url("@/assets/BigImage/product-home1.jpg");
  background-size: 100% 100%;
  position: relative;
  .home2-right-menu {
    width: 68%;
    height: 560px;
    position: absolute;
    left: 0;
    bottom: 0;
    .home2-right-menu-col {
      height: 140px;
      width: 100%;
      background-color: #0515ef;
      color: #ffffff;
      padding-left: 68px;
      box-sizing: border-box;
      padding-top: 4%;
      // line-height: 140px;
      .main-menu-name {
        font-size: 35px;
        font-family: SourceHanSansSC;
        font-weight: bold;
      }
      .second-menu-name {
        font-size: 30px;
      }
    }
    .home2-right-menu-col:hover {
      background-color: rgba(10, 10, 43, 0.5);
      cursor: pointer;
    }
    .home2-right-menu-col:hover .main-menu-name {
      color: #73c74e;
    }
  }
}
.pic-list {
  height: 66px;
  overflow: hidden;
  border-bottom: solid 1px #fff;
  position: relative;
}
.pic-list ul {
  position: absolute;
  left: 0px;
  top: 0px;
}
li {
  list-style: none;
}
.pic-list .picture {
  float: left;
  width: 238px;
  height: 66px;
  // border-right: solid 1px #fff;
  position: relative;
  margin: 0 25px;
  // background: #eee;
}
.about-3-logos-card {
  width: 188px;
  height: 66px;
  margin: 0 25px;
  img {
    width: 100%;
    height: 100%;
  }
}
.about-4-left {
  width: 50%;
  height: 100%;
  float: left;
  background-image: url("@/assets/BigImage/new-main-1.jpg");
  background-size: 100% 100%;
  .about-title {
    width: 755px;
    height: 200px;
    position: absolute;
    left: 10%;
    top: 40%;
    user-select: none;
    .about-title-text {
      width: 100%;
      color: #fff;
      font-size: 80px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .about-title-base {
      width: 147px;
      height: 12px;
      background-color: #4fbf35;
      // margin-left: 8px;
    }
  }
  .about-descript {
    width: 520px;
    height: 200px;
    position: absolute;
    top: 55%;
    left: 15%;
    color: #fff;
    font-size: 16px;
  }
}
.about-4-right {
  width: 50%;
  height: 100%;
  float: left;
  background-image: url("@/assets/BigImage/new-main-2.jpeg");
  background-size: 100% 100%;
}
.home-index {
  position: absolute;
  width: 64px;
  height: 64px;
  left: calc(50% - 32px);
  top: 50px;
  background-color: #fff;
  border: 1px solid #002047;
  text-align: center;
  line-height: 61px;
  box-sizing: border-box;
  font-size: 34px;
  font-family: SourceHanSansSC;
  z-index: 5;
  border-radius: 30px;
}
@keyframes wobble {
  0% {
    transform: scale(0, 0);
  }
  1% {
    transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    transform: scale(0.96274018852263, 1.006488198218787);
  }
  18% {
    transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    transform: scale(1.008821093113781, 0.999833501948366);
  }
  25% {
    transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    transform: scale(0.999833501948366, 1.000014970213524);
  }
  37% {
    transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    transform: scale(0.999930953553717, 1.000000476269022);
  }
  47% {
    transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    transform: scale(1.000000687807928, 1.00000000065078);
  }
  71% {
    transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    transform: scale(1.000000456728239, 0.999999999724765);
  }
  74% {
    transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    transform: scale(1.000000000606774, 1.000000000000103);
  }
}
#home4titles {
  width: 100%;
  color: #fff;
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 5px;
  span {
    -webkit-animation: 5s wobble linear infinite;
    animation: 5s wobble linear infinite;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
  }
}
</style>
<style scoped>
.myHomeCarousel >>> .el-carousel .el-carousel__indicators--vertical {
  left: 45px;
  top: 80%;
  width: 20px;
}
.myHomeCarousel >>> .el-carousel__arrow--left {
  display: none;
}
.myHomeCarousel >>> .el-carousel__arrow--right {
  display: none;
}
.myHomeCarousel >>> .el-carousel__indicators {
  display: none;
}
.trends-title {
  width: 100%;
  color: #fff;
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 15px;
  -webkit-animation: 8s wobble linear infinite;
  animation: 8s wobble linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
#home3titles {
  font-size: 85px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
}

#home4descs {
  width: 520px;
  height: 200px;
  position: absolute;
  top: 55%;
  left: 10%;
  color: #fff;
  font-size: 16px;
}
#home3descs {
  width: 190px;
  color: #ffffff;
  font-size: 19px;
  text-align: center;
  margin-top: 30px;
  margin-left: 30px;
}
.trends-title2 {
  -webkit-animation: 1s wobble11 linear infinite;
  animation: 1s wobble11 linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
.trends-desc2 {
  -webkit-animation: 1s wobble12 linear infinite;
  animation: 1s wobble12 linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
.trends-title3 {
  -webkit-animation: 1s wobble21 linear infinite;
  animation: 1s wobble21 linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
.trends-desc3 {
  -webkit-animation: 1s wobble22 linear infinite;
  animation: 1s wobble22 linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
#home4desc {
  width: 520px;
  height: 200px;
  position: absolute;
  top: 58%;
  left: 15%;
  color: #fff;
  font-size: 16px;
}
.trends-desc1 {
  -webkit-animation: 2s descFrames linear infinite;
  animation: 2s descFrames linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
.trends-desc {
  -webkit-animation: 2s descFrames1 linear infinite;
  animation: 2s descFrames1 linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
#realhome1desc {
  width: 420px;
  height: 200px;
  position: absolute;
  top: 66%;
  right: 10%;
  color: #fff;
  font-size: 16px;
  overflow: auto;
}
@keyframes wobble {
  0% {
    transform: scale(0, 0);
  }
  1% {
    transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    transform: scale(0.96274018852263, 1.006488198218787);
  }
  18% {
    transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    transform: scale(1.008821093113781, 0.999833501948366);
  }
  25% {
    transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    transform: scale(0.999833501948366, 1.000014970213524);
  }
  37% {
    transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    transform: scale(0.999930953553717, 1.000000476269022);
  }
  47% {
    transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    transform: scale(1.000000687807928, 1.00000000065078);
  }
  71% {
    transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    transform: scale(1.000000456728239, 0.999999999724765);
  }
  74% {
    transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    transform: scale(1.000000000606774, 1.000000000000103);
  }
}
@keyframes descFrames {
  0% {
    top: 100%;
    opacity: 0;
  }
  50% {
    top: 80%;
    opacity: .5;
  }
  100% {
    top: 65%;
    opacity: 1;
  }
}
@keyframes descFrames1 {
  0% {
    top: 100%;
    opacity: 0;
  }
  50% {
    top: 80%;
    opacity: .5;
  }
  100% {
    top: 58%;
    opacity: 1;
  }
}
@keyframes wobble11 {
  0% {
    padding-top: 50px;
    opacity: 0;
  }
  50% {
    padding-top: 25px;
    opacity: .5;
  }
  100% {
    padding-top: 0px;
    opacity: 1;
  }
}
@keyframes wobble12 {
  0% {
    padding-top: 50px;
    opacity: 0;
  }
  50% {
    padding-top: 25px;
    opacity: .5;
  }
  100% {
    padding-top: 0px;
    opacity: 1;
  }
}
@keyframes wobble21 {
  0% {
    padding-top: 50px;
    opacity: 0;
  }
  50% {
    padding-top: 25px;
    opacity: .5;
  }
  100% {
    padding-top: 0px;
    opacity: 1;
  }
}
@keyframes wobble22 {
  0% {
    top: 100%;
    opacity: 0;
  }
  50% {
    top: 65%;
    opacity: .5;
  }
  100% {
    top: 55%;
    opacity: 1;
  }
}
</style>