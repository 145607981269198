<template>
  <div v-loading="isLoading" class="product">
    <el-carousel
      ref="carouselRef"
      :initial-index="0"
      class="myCarousel"
      :height="screenHeight + 'px'"
      direction="vertical"
      :loop="false"
      :autoplay="false"
      @change="handleChangeCarousel"
    >
      <el-carousel-item name="0">
        <div class="about-card about-1">
          <div class="about-logo" @click="handleReturnHome"></div>
          <div class="about-btns" @click="handleShowMenu"></div>
          <div class="about-next-card" @click="handleNext"></div>
          <div class="about-title">
            <div id="product1title" class="about-title-text">{{ $t("bilingual0029") }}</div>
            <div class="about-title-base"></div>
          </div>
          <!-- <div class="about-left-and-right">
            <div class="left-arrow" @click="handlePrevRouter"></div>
            <div class="right-arrow" @click="handleNextRouter"></div>
          </div> -->
          <div class="product-1-bg"></div>
          <div class="product-1-imgs">
            <div class="product-1-image" style="border-right: 1px solid rgba(255, 255, 255,.7);">
              <div class="product-1-imgbg">
                <!-- <img class="show-image-name" src="@/assets/BigImage/product-1-1.png" alt="" style="height: 70%;"> -->
                <img class="show-image-name" :src="product01List[0]?.image" alt="" style="height: 70%;">
              </div>
              <div class="product-1-tools">
                <!-- <div class="product-1-tools-name">{{ $t("bilingual0030") }}</div> -->
                <div class="product-1-tools-name">{{ product01List[0]?.name }}</div>
                <div class="product-1-tools-btn">
                  <img src="@/assets/Icons/radius-right1.png" alt="" @click="handleTurnDetail(0)">
                </div>
              </div>
            </div>

            <div class="product-1-image">
              <div class="product-1-imgbg">
                <!-- <img class="show-image-name" src="@/assets/BigImage/product-1-2.png" alt="" style="height: 70%;"> -->
                <img class="show-image-name" :src="product01List[1]?.image" alt="" style="height: 70%;">
              </div>
              <div class="product-1-tools">
                <!-- <div class="product-1-tools-name">{{ $t("bilingual0031") }}</div> -->
                <div class="product-1-tools-name">{{ product01List[1]?.name }}</div>
                <div class="product-1-tools-btn">
                  <img src="@/assets/Icons/radius-right1.png" alt="" @click="handleTurnDetail(1)">
                </div>
              </div>
            </div>

            <div class="product-1-image" style="border-right: 1px solid rgba(255, 255, 255,.7);border-top: 1px solid rgba(255, 255, 255,.7);">
              <div class="product-1-imgbg">
                <!-- <img class="show-image-name" src="@/assets/BigImage/product-1-3.png" alt="" style="height: 77%;"> -->
                <img class="show-image-name" :src="product01List[2]?.image" alt="" style="height: 77%;">
              </div>
              <div class="product-1-tools">
                <!-- <div class="product-1-tools-name">{{ $t("bilingual0032") }}</div> -->
                <div class="product-1-tools-name">{{ product01List[2]?.name }}</div>
                <div class="product-1-tools-btn">
                  <img src="@/assets/Icons/radius-right1.png" alt="" @click="handleTurnDetail(2)">
                </div>
              </div>
            </div>

            <div class="product-1-image" style="border-top: 1px solid rgba(255, 255, 255,.7);">
              <div class="product-1-imgbg">
                <!-- <img class="show-image-name" src="@/assets/BigImage/product-1-4.png" alt="" style="height: 70%;"> -->
                <img class="show-image-name" :src="product01List[3]?.image" alt="" style="height: 70%;">
              </div>
              <div class="product-1-tools">
                <!-- <div class="product-1-tools-name">{{ $t("bilingual0033") }}</div> -->
                <div class="product-1-tools-name">{{ product01List[3]?.name }}</div>
                <div class="product-1-tools-btn">
                  <img src="@/assets/Icons/radius-right1.png" alt="" @click="handleTurnDetail(3)">
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item name="1">
        <div class="about-card about-2">
          <div class="about-logo2" @click="handleReturnHome"></div>
          <div class="about-btns2" @click="handleShowMenu"></div>
          <detail :detail-id="detailId" :productList="productList" @handoeCloseChildCollapse="handoeCloseChildCollapse"/>
        </div>
      </el-carousel-item>
    </el-carousel>
    <MyFooter v-if="itemIndex == 1" />
    <Menu ref="menu" />
  </div>
</template>
<script>
import { debounce } from "@/utils";
import MyFooter from "@/components/MyFooter.vue";
import detail from "./productInfo/productDetail.vue"
import Menu from "@/components/menu.vue"
export default {
  name: "product",
  components: {
    MyFooter, detail, Menu
  },
  data() {
    return {
      itemIndex: 0,
      detailId: null,
      product01List: Array(4),
      isLoading: false,
      productList: [],
    };
  },
  watch: {
    itemIndex(val1,val2) {
      if(val1 == '0') {
        setTimeout(() => {
          document.getElementById("product1title").className="trends-title";
        },300)
      }else{
        document.getElementById("product1title").className="about-title-text";
      }
    }
  },
  computed: {
    screenHeight() {
      var clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight;
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight;
      }
      return clientHeight;
    },
  },
  created() {
    this.doInit();
  },
  mounted() {
    console.log('产品')
    setTimeout(() => {
      document.getElementById("product1title").className="trends-title";
    },300)
    window.addEventListener(
      "wheel",
      debounce(this.handleMouseWheel, 100, false),
      {
        passive: false,
      }
    );
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleMouseWheel, {
      passive: false,
    });
  },
  methods: {
    doInit() {
      this.isLoading = true;
      this.axios({
        url: '/api/index//getList',
        method: 'get',
        params: {
          category: '2',
          lang:  (localStorage.getItem("language") == 'zh' || localStorage.getItem("language") == 'ch') ? 'zh': localStorage.getItem("language"),
        }
      }).then(resp => {
        console.log(140, resp)
        let res = resp.data.data;
        this.product01List = res.child.map(item => {
          return {
            id: item.id,
            name: item.name,
            image: item?.thumbnail
          }
        })
        // this.productList = res.child.map(item => {
        //   return {
        //     id: item.id,
        //     name: item.name,
        //     children: item.list.map(i => {
        //       return {
        //         title: i.title,
        //         desc: i.excerpt,
        //         image: i.thumbnail,
        //         photo: i.photos?.length == 2 ? i?.photos[0]: '',
        //         photoDetail: i.photos?.length == 2 ? i?.photos[1]: '',
        //         children: []
        //       }
        //     })
        //   }
        // })
        this.productList = res.child.map((item,index1) => {
          return {
            id: index1+1,
            tableId: item.id,
            name: item.name,
            level: 1,
            bottomLevel: (item.child && item.child.length > 0) || (item.list && item.list.length > 0),
            children: item.child.length == 0 ? 
            item.list.map((col1,coli1) => {
              return {
                id: index1+'-'+coli1,
                tableId: col1.id,
                title: col1.title,
                name: col1.title,
                desc: col1.excerpt,
                image: col1.thumbnail,
                photo: col1.photos?.length == 2 ? col1?.photos[0]: '',
                photoDetail: col1.photos?.length == 2 ? col1?.photos[1]: '',
                level: 1,
                bottomLevel: false,
              }
            })
            : 
            item.child.map((i,index2) => {
              return {
                id: index1+'%'+index2,
                tableId: i.id,
                name: i.name,
                level: 2,
                bottomLevel: (i.child && i.child.length > 0) || (i.list && i.list.length > 0),
                children:
                i.child.length == 0 ? 
                i.list.map((col2,coli2) => {
                  return {
                    id: index1 + '%' + index2 + '-' + coli2,
                    tableId: col2.id,
                    title: col2.title,
                    name: col2.title,
                    desc: col2.excerpt,
                    image: col2.thumbnail,
                    photo: col2.photos?.length == 2 ? col2?.photos[0]: '',
                    photoDetail: col2.photos?.length == 2 ? col2?.photos[1]: '',
                    level: 2,
                    bottomLevel: false,
                  }
                })
                :
                i.child.map((val,index3) => {
                  return {
                    id: index1+'%'+index2+'%'+index3,
                    tableId: val.id,
                    name: val.name,
                    title: val.name,
                    level: 3,
                    bottomLevel: (val.child && val.child.length > 0) || (val.list && val.list.length > 0),
                    children: val.list.map((c, index4) => {
                      return {
                        id: index1+'%'+index2+'%'+index3+'%'+index4,
                        tableId: c.id,
                        title: c.title,
                        name: c.title,
                        desc: c.excerpt,
                        image: c.thumbnail,
                        photo: c.photos?.length == 2 ? c?.photos[0]: '',
                        photoDetail: c.photos?.length == 2 ? c?.photos[1]: '',
                        level: 4,
                        bottomLevel: false,
                      }
                    })
                  }
                })
              }
            })
          }
        })
        console.log(203, this.productList)
        this.isLoading = false;
      })
    },
    handleMouseWheel(e) {
      if (!window.scrollY) {
        if (e.wheelDelta) {
          // 判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) {
            this.handlePrev();
          }
          if (e.wheelDelta < 0) {
            // 当滑轮向下滚动时
            this.handleNext();
          }
        } else if (e.detail) {
          // Firefox滑轮事件
          if (e.detail > 0) {
            // 当滑轮向下滚动时
            this.handleNext();
          }
          if (e.detail < 0) {
            // 当滑轮向上滚动时
            this.handlePrev();
          }
        }
      }
    },
    handleNext() {
      this.$refs.carouselRef?.next();
    },
    handlePrev() {
      this.$refs.carouselRef?.prev();
    },
    handleChangeCarousel(newValue, oldValue) {
      this.itemIndex = newValue;
      console.log(280 , newValue)
      if(newValue == 1) {
        // this.detailId = 0;
      }
      // this.detailId = detailId;
    },
    handlePrevRouter() {
      this.$store.dispatch("myTools/switchRouter", "product").then((res) => {
        if (res.prev) {
          this.$router.push({
            path: res.prev,
          });
        }
      });
    },
    handleNextRouter() {
      this.$store.dispatch("myTools/switchRouter", "product").then((res) => {
        if (res.next) {
          this.$router.push({
            path: res.next,
          });
        }
      });
    },
    handleTurnDetail(detailId) {
      console.log(256, detailId)
      this.detailId = detailId;
      console.log(258, this.detailId)
      this.handleNext();
    },
    handoeCloseChildCollapse() {
      this.detailId = null;
    },
    handleShowMenu() {
      this.$refs.menu.handleShow();
    },
    handleReturnHome() {
      this.$router.push({ path: '/' });
    },
  },
};
</script>
<style lang="scss" scoped>
.product {
  width: 100%;
  overflow: hidden;
}
.about-card {
  width: 100%;
  height: 100vh;
  position: relative;
}
.about-logo {
  // width: 130px;
  // height: 83px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-write.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.about-logo2 {
  // width: 130px;
  // height: 83px;
  width: 110px;
  height: 70px;
  position: absolute;
  left: 40px;
  top: 40px;
  // background-color: #fff;
  background-image: url("@/assets/Icons/logo-blue.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.about-btns {
  width: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
}
.about-btns2 {
  width: 60px;
  height: 20px;
  background-image: url("@/assets/Icons/more-blue3.png");
  background-size: 100% 100%;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
  z-index: 5;
}
.about-next-card {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn.png");
  background-size: 100% 100%;
  opacity: 0.4;
  z-index: 9;
}
.about-next-card:hover {
  cursor: pointer;
  opacity: 0.9;
}
.about-next-card2 {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  display: block;
  z-index: 1;
  cursor: pointer;
  // background-color: rgba(255,0,0,.1);
  background-image: url("@/assets/Icons/next-btn2.png");
  background-size: 100% 100%;
  opacity: 0.4;
  z-index: 9;
}
.about-next-card2:hover {
  cursor: pointer;
  opacity: 0.9;
}
.about-1 {
  // background-image: url("@/assets/BigImage/about-1-1.jpg");
  // background-size: 100% 100%;
  .product-1-bg {
    width: calc(50% + 15px);
    height: 100%;
    background: url("@/assets/BigImage/product-1-bg.png") no-repeat center center;
    background-size: 100% 100%;
    float: left;
  }
  .product-1-imgs {
    width: calc(50% - 15px);
    height: 100%;
    background-color: #0024f2;
    float: left;
    .product-1-image {
      width: 50%;
      height: 50%;
      float: left;
      box-sizing: border-box;
      .product-1-imgbg {
        width: 100%;
        height: calc(100% - 80px);
        text-align: center;
        padding-top: 27%;
      }
      .product-1-tools {
        width: 100%;
        height: 80px;
        // background-color: red;
        .product-1-tools-name {
          height: 100%;
          width: calc(100% - 80px);
          float: left;
          text-align: left;
          font-size: 24px;
          font-family: SourceHanSansSC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 80px;
          padding-left: 80px;
        }
        .product-1-tools-btn {
          width: 80px;
          height: 100%;
          float: left;
          // background-color: red;
          padding-top: 20px;
          img {
            width: 40px;
            height: 40px;
            opacity: .7;
          }
          img:hover {
            opacity: 1;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.about-2 {
  background-color: #FFF;
}
.about-title {
  width: 755px;
  height: 200px;
  position: absolute;
  left: 10%;
  top: 43%;
  user-select: none;
  .about-title-text {
    width: 100%;
    color: #fff;
    font-size: 90px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .about-title-base {
    width: 150px;
    height: 12px;
    background-color: #4fbf35;
    margin-left: 8px;
  }
}
.about-title2 {
  width: 755px;
  height: 350px;
  position: absolute;
  left: 10%;
  top: 13%;
  user-select: none;
  .about-title2-text {
    width: 100%;
    color: #0015ff;
    font-size: 80px;
    font-weight: 600;
  }
  .about-title2-base {
    width: 140px;
    height: 12px;
    background-color: #4fbf35;
    margin-left: 8px;
    margin-bottom: 48px;
  }
  .about-title2-descrpt {
    width: 100%;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #0015ff;
    line-height: 27px;
    padding-right: 318px;
    padding-left: 8px;
  }
}
.about-title3 {
  width: 74%;
  height: 200px;
  position: absolute;
  left: 13%;
  top: 2%;
  user-select: none;
  .about-t-title-content {
    height: 100%;
    width: 50%;
    float: left;
    .about-title3-text {
      width: 755px;
      color: #0015ff;
      font-size: 80px;
      font-weight: 600;
      line-height: 80px;
      margin-bottom: 16px;
      padding-top: 10px;
    }
    .about-title3-base {
      width: 140px;
      height: 12px;
      background-color: #4fbf35;
      margin-left: 8px;
      margin-bottom: 48px;
    }
  }
  .about-title3-descrpt {
    width: 50%;
    height: 100%;
    font-size: 30px;
    font-weight: 600;
    font-family: SourceHanSansSC;
    font-weight: 500;
    color: #0015ff;
    line-height: 40px;
    float: right;
    padding: 25px 0 0 25%;
  }
}
.about-descript {
  width: 420px;
  height: 200px;
  position: absolute;
  top: 75%;
  right: 10%;
  color: #fff;
  font-size: 16px;
}
.about-left-and-right {
  width: 200px;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgb(49, 49, 255);
  padding: 0 50px;
  .left-arrow {
    width: 40px;
    height: 40px;
    float: left;
    background-image: url("@/assets/Icons/left-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
  .right-arrow {
    width: 40px;
    height: 40px;
    float: right;
    background-image: url("@/assets/Icons/right-arrow.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
}
.product {
  .el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .el-carousel__item:nth-child(2n + 1) {
      // background-color: #d3dce6;
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators {
      // 指示器
      left: unset;
      transform: unset;
      right: 2%;
    }
    /deep/ .el-carousel__button {
      // 指示器按钮
      width: 10px;
      height: 10px;
      border: none;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {
      // 指示器激活按钮
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators--vertical {
      left: 45px;
      top: 80%;
      width: 20px;
    }
  }
}
.about-4 {
  .el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .el-carousel__item:nth-child(2n + 1) {
      // background-color: #d3dce6;
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators {
      // 指示器
      left: unset;
      transform: unset;
      right: 2%;
      display: none;
    }
    /deep/ .el-carousel__button {
      // 指示器按钮
      width: 10px;
      height: 10px;
      border: none;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      // border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {
      // 指示器激活按钮
      background: red;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-carousel__indicators--vertical {
      left: 45px;
      top: 80%;
      width: 20px;
    }
  }
}
</style>
<style  scoped>
.myCarousel >>> .el-carousel .el-carousel__indicators--vertical {
  left: 45px;
  top: 80%;
  width: 20px;
}
#home1desc {
  width: 100%;
  color: #fff;
  font-size: 90px;
  font-weight: 600;
  margin-bottom: 15px;
}
.trends-title {
  width: 100%;
  color: #fff;
  font-size: 85px;
  font-weight: 600;
  margin-bottom: 15px;
  -webkit-animation: 8s wobble linear infinite;
  animation: 8s wobble linear infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}
@keyframes wobble {
  0% {
    transform: scale(0, 0);
  }
  1% {
    transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    transform: scale(0.96274018852263, 1.006488198218787);
  }
  18% {
    transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    transform: scale(1.008821093113781, 0.999833501948366);
  }
  25% {
    transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    transform: scale(0.999833501948366, 1.000014970213524);
  }
  37% {
    transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    transform: scale(0.999930953553717, 1.000000476269022);
  }
  47% {
    transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    transform: scale(1.000000687807928, 1.00000000065078);
  }
  71% {
    transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    transform: scale(1.000000456728239, 0.999999999724765);
  }
  74% {
    transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    transform: scale(1.000000000606774, 1.000000000000103);
  }
}
.show-image-name {
  transition: all 0.4s;
}
.show-image-name:hover {
  /* box-shadow: 0 8px 8px 0 grey; */
  transform: scale(1.3,1.3);
}

</style>